import { withStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";

const ValidationTextField = withStyles({
  root: {
    "& input": {
      padding: "15px 14px",
    },
    "& input:valid + fieldset": {
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    width: "50%",
  },
})(TextField);

const ValidationTextFieldFullWidth = withStyles({
  root: {
    "& input": {
      padding: "15px 14px",
    },
    "& input:valid + fieldset": {
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    width: "100%",
  },
})(TextField);

export { ValidationTextField, ValidationTextFieldFullWidth };
