import React, { useEffect } from "react";
import * as constants from "../../assets/constants";
import callFlagReviewStyles from "./CallFlagReview.css";
import "./CallFlagReview.css";
import StyledListSubheader from "../../assets/components/StyledListSubheader";
import InfoIcon from "@material-ui/icons/Info";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import CategoryIcon from "@material-ui/icons/Category";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import TextField from "@mui/material/TextField";
import StyledAutocomplete from "../../assets/components/StyledAutocomplete";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlagIcon from "@mui/icons-material/Flag";
import Badge from "@mui/material/Badge";
import { getCallFlagsSummary, createCallFlagsSelected, removeCallFlagsSelected, reviewCallFlagsSelected } from "../../services/callFlagReviewService";
import Alert from "@material-ui/lab/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import { Typography } from "@material-ui/core";
import HtmlTooltip from "../../assets/components/HtmlTooltip";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import StyledFlagChip from "../../assets/components/StyledFlagChip/StyledFlagChip";

const CallFlagReview = (props) => {
  const styles = callFlagReviewStyles();

  const initialErrorObject = {
    isError: false,
    msgError: "",
  };

  const [callSummaryReq] = React.useState({
    clientKey: props.clientKey ? props.clientKey : 0,
    convID: props.fileId,
  });
  const [callFlagsInfo, setCallFlagsInfo] = React.useState([]);
  const [callFlagsSelected, setCallFlagsSelected] = React.useState([]);
  const [flagsSelectedCount, setFlagsSelectedCount] = React.useState(0);
  const [previousCallFlagsSelected, setPreviousCallFlagsSelected] = React.useState([]);
  const [isAdmin] = React.useState(props.isAdmin);
  const [errorObject, setErrorObject] = React.useState(initialErrorObject);

  useEffect(() => {
    (async () => {
      try {
        // get call flag summary
        await getCallSummary();
      } catch (err) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Failed to initialize call flag component",
        });
      }
    })();
  }, []);

  useEffect(() => {
    let count = props.utteranceFlagsSelected?.length + callFlagsSelected?.length;
    setFlagsSelectedCount(count);
  }, [props.utteranceFlagsSelected, callFlagsSelected]);

  const renderIcon = (category) => {
    switch (category) {
      case constants.REMOVE_CATEGORY:
        return <LibraryAddCheckIcon fontSize="small" />;
      case constants.COMPLIMENTARY_CATEGORY:
        return <ThumbUpAltIcon fontSize="small" />;
      case constants.IMPROVEMENT_CATEGORY:
        return <TrendingUpIcon fontSize="small" />;
      case constants.INFORMATIONAL_CATEGORY:
        return <InfoIcon fontSize="small" />;
      case constants.OTHER_CATEGORY:
        return <CategoryIcon fontSize="small" />;
      default:
        return <AssistantPhotoIcon fontSize="small" />;
    }
  };

  const getCallSummary = async () => {
    // get call flag summary
    try {
      let callFlagSummary = await getCallFlagsSummary(callSummaryReq);
      if (callFlagSummary?.data?.length !== 0) {
        callFlagSummary.data = callFlagSummary.data.filter((flag) => flag.Category !== "MEDDICC Responses");
        setCallFlagsInfo(callFlagSummary.data);
        let callFlagSelectedList = callFlagSummary?.data?.filter((v) => v.Selected === true);
        setCallFlagsSelected(callFlagSelectedList);
        setPreviousCallFlagsSelected(callFlagSelectedList);
      }
    } catch (err) {
      setErrorObject({
        isError: true,
        msgError: "ERR: Failed to update call flags",
      });
      setCallFlagsSelected([...previousCallFlagsSelected]);
    }
  };

  const handleFlagDelete = async (FlagID) => {
    if (callFlagsSelected.length === 1) {
      setErrorObject({
        severity: "warning",
        isError: true,
        msgError: "Please select an additional flag reason first.",
      });
    } else {
      // call Delete for API for updates
      if (FlagID !== 0) {
        // API Call
        try {
          await removeCallFlagsSelected({
            ConversationID: props.fileId,
            FlagIDArr: [FlagID],
          });
        } catch (err) {
          setErrorObject({
            isError: true,
            msgError: "ERR: Failed to delete selected flag",
          });
          setCallFlagsSelected([...previousCallFlagsSelected]);
        }
      }
      // Refresh callSummary
      await getCallSummary();
    }
  };

  const handleFlagReview = async (FlagID) => {
    // call Delete for API for updates
    if (FlagID !== 0) {
      // API Call
      try {
        await reviewCallFlagsSelected({
          ConversationID: props.fileId,
          FlagIDArr: [FlagID],
          UserKey: props.userKeyInSession,
        });
      } catch (err) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Failed to review selected flag",
        });
        setCallFlagsSelected([...previousCallFlagsSelected]);
      }
    }
    // Refresh callSummary
    await getCallSummary();
  };

  const handleFlagChange = (e, value) => {
    setErrorObject({
      isError: false,
      msgError: "",
    });
    // setCallFlagsSelected([]);
    setCallFlagsSelected(value);
  };

  const saveFlagChange = async () => {
    setErrorObject({
      isError: false,
      msgError: "",
    });
    // find unique in both objects
    const unique = (a) => (o) => !a.some(({ FlagID }) => o.FlagID === FlagID);
    let uniqueFirst = previousCallFlagsSelected.filter(unique(callFlagsSelected));
    let uniqueSecond = callFlagsSelected.filter(unique(previousCallFlagsSelected));
    // call Delete for API for updates
    if (uniqueFirst?.length !== 0) {
      // API Call
      try {
        let listOfFlagIDToDelete = uniqueFirst.map((obj) => {
          return obj.FlagID;
        });
        await removeCallFlagsSelected({
          ConversationID: props.fileId,
          FlagIDArr: listOfFlagIDToDelete,
        });
      } catch (err) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Failed to delete selected flag",
        });
        setCallFlagsSelected([...previousCallFlagsSelected]);
      }
    }
    // call Save API for updates
    if (uniqueSecond?.length !== 0) {
      // API Call
      try {
        let listOfFlagIDToSave = uniqueSecond.map((obj) => {
          return obj.FlagID;
        });
        await createCallFlagsSelected({
          ConversationID: props.fileId,
          FlagIDArr: listOfFlagIDToSave,
          UserKey: props.userKeyInSession,
        });
      } catch (err) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Failed to save selected flag",
        });
        setCallFlagsSelected([...previousCallFlagsSelected]);
      }
    }
    // Refresh call flag summary
    if (uniqueFirst?.length !== 0 || uniqueSecond?.length !== 0) {
      await getCallSummary();
    }
  };

  const renderGroup = (params) => {
    if (params.group !== constants.REMOVE_CATEGORY) {
      return [
        <div key={`cfr-rg-div-${params.key}`} className={styles.menuItemDiv}>
          <span className={styles.groupHeaderIcon}>{renderIcon(params.group)}</span>
          <StyledListSubheader key={`cfr-rg-div-slsh-${params.key}`} component="div">
            {params.group}
          </StyledListSubheader>
        </div>,
        params.children,
      ];
    }
  };

  const renderOption = (props, option, { selected }) => {
    if (option.Category !== constants.REMOVE_CATEGORY) {
      return (
        <div
          style={{
            cursor: isAdmin ? "pointer" : selected && option.Reviewed ? "not-allowed" : "pointer",
          }}
        >
          <li
            {...props}
            style={{
              background: selected ? "#F5F5F5" : undefined,
              color: selected ? "#1077a8" : undefined,
              pointerEvents: isAdmin ? "auto" : option.Reviewed ? "none" : "auto",
            }}
          >
            <Typography className={styles.optionUL} key={option.FlagID} value={option.FlagID}>
              {option.Description}{" "}
            </Typography>
          </li>
        </div>
      );
    } else {
      return [];
    }
  };

  const getFastForwardLabel = (item) => {
    let label = "";
    let description = "";
    let time = "";
    if (item.FlagID !== 0 && props.utteranceFlags.length > 0) {
      let flagInfo = props.utteranceFlags.filter((v) => v.FlagID === item.FlagID);
      description = flagInfo[0]?.Description;
      time = props.isSnippetCall ? constants.toHHMMSS(item.revisedStartTime, props.isLongCall) : constants.toHHMMSS(item.StartTime, props.isLongCall);
      label = description + " " + time;
    }
    return label;
  };

  const handleFastForward = (item) => {
    props.seekByFastForward(item.MessageID);
  };

  return (
    <React.Fragment>
      <div className={styles.callFlagRoot}>
        {props.dockState ? (
          <Accordion elevation={0} defaultExpanded={true}>
            <AccordionSummary
              className={styles.accordion_default_with_hover}
              expandIcon={<ExpandMoreIcon className={styles.svgExpandMoreIconDefault} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FlagIcon color="action" style={{ marginRight: "4px" }} />
              <Typography className={styles.drawer_label}>Flags</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ marginTop: "8px", paddingBottom: "8px" }}>
              {errorObject.isError ? (
                <Alert className={styles.alert} severity={errorObject.severity ? errorObject.severity : "error"}>
                  {errorObject.msgError}
                </Alert>
              ) : undefined}
              <StyledAutocomplete
                id="call-flag-review-tags"
                options={callFlagsInfo}
                value={callFlagsSelected}
                noOptionsText="No Flag Reasons"
                disableCloseOnSelect={true}
                multiple
                getOptionLabel={(option) => option.Description}
                groupBy={(option) => option.Category}
                renderTags={() => null}
                renderInput={(params) => <StyledTextField {...params} variant="outlined" label="Select reason(s)" />}
                clearIcon={<ClearIcon fontSize="small" style={{ display: "none" }} />}
                renderGroup={renderGroup}
                renderOption={renderOption}
                onChange={(event, value) => handleFlagChange(event, value)}
                onClose={(event) => saveFlagChange(event)}
                isOptionEqualToValue={(option, value) => option.FlagID === value.FlagID}
              />
              <div className={styles.valueContainer}>
                {callFlagsSelected
                  .sort((a, b) => b.Reviewed - a.Reviewed)
                  .map((v) =>
                    v.Reviewed ? (
                      <StyledFlagChip
                        key={v.FlagID}
                        handleFlagDelete={() => handleFlagDelete(v.FlagID)}
                        chipText={v.Description}
                        isAdmin={props.isAdmin}
                        isReviewed={v.Reviewed}
                        isUtteranceFlag={false}
                        isFastForward={false}
                      ></StyledFlagChip>
                    ) : (
                      <StyledFlagChip
                        id="1"
                        key={v.FlagID}
                        handleFlagDelete={() => handleFlagDelete(v.FlagID)}
                        handleFlagReview={() => handleFlagReview(v.FlagID)}
                        chipText={v.Description}
                        isAdmin={props.isAdmin}
                        isReviewed={v.Reviewed}
                        isUtteranceFlag={false}
                        isFastForward={false}
                      ></StyledFlagChip>
                    )
                  )}

                {props.utteranceFlagsSelected.length > 0
                  ? props.utteranceFlagsSelected
                      .sort((a, b) => a.StartTime - b.StartTime)
                      .map((v) => (
                        <StyledFlagChip
                          key={`ff_+${v.FlagID}+_+${v.MessageID}`}
                          chipText={getFastForwardLabel(v)}
                          isAdmin={isAdmin}
                          isReviewed={v.Reviewed}
                          isUtteranceFlag={true}
                          handleFastForward={() => handleFastForward(v)}
                          isFastForward={true}
                        ></StyledFlagChip>
                      ))
                  : undefined}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : (
          <div className={styles.callFlagsDocked}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className={styles.participantText}>Flags</Typography>
                </React.Fragment>
              }
              placement="right"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <Button
                sx={{
                  borderRadius: "10px",
                  outline: "0 !important",
                  height: "52px",
                }}
                onClick={props.handleDrawerOpen}
              >
                <Badge badgeContent={flagsSelectedCount} color="primary">
                  <FlagIcon color="action" />
                </Badge>
              </Button>
            </HtmlTooltip>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CallFlagReview;

const StyledTextField = withStyles({
  root: {
    "& input": {
      color: "#495057",
      fontSize: "14px",
    },
    "& fieldset": {
      borderRadius: "10px",
      fontSize: "14px",
    },
    "& textarea": {
      color: "#495057",
      fontSize: "14px",
    },
  },
})(TextField);
