import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@mui/icons-material/Error";

export const FailureIcon = withStyles(() => ({
  root: {
    color: "#F76707",
    width: "inherit !important",
    height: "inherit !important",
    verticalAlign: "inherit",
  },
}))(ErrorIcon);
