import { withStyles } from "@material-ui/core/styles";
import DoNotDisturbOnRoundedIcon from "@mui/icons-material/DoNotDisturbOnRounded";

export const DisabledIcon = withStyles(() => ({
  root: {
    color: "#ADB5BD",
    width: "inherit !important",
    height: "inherit !important",
    verticalAlign: "inherit",
  },
}))(DoNotDisturbOnRoundedIcon);
