import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Box } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import Logo from "../assets/logo_gryphonai_fff.svg";
import config from "../config/config";
import * as cookieUtils from "../assets/cookie-utils";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as ProfileDropDownIcon } from "../assets/svgs/gn-profile-dropdown.svg";
import { ReactComponent as ExternalLinkIcon } from "../assets/svgs/gn-external-link.svg";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./Navbar.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    bacolor: "blue",
  },
  navbar: {
    color: "black",
    background: "linear-gradient(311deg, #4BC957 2.09%, #47C35D 14.92%, #3EB371 33.46%, #309890 55.51%, #1C75BC 79.85%, #2B3990 96.48%)",
    zIndex: theme.zIndex.drawer + 1,
  },
  appHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    maxHeight: "3em",
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "sapce-between",
  },
  toggleContentWrapper: {
    marginLeft: "5%",
    display: "inline-flex",
  },

  popOver: {
    left: "calc(100vw - 12vw)",
    top: "calc(100vh - 95vh)",
  },
  toggleButtonGroup: {
    "& .Mui-selected": {
      backgroundColor: "#FFFFFF !important",
      opacity: "0.3",
      borderRadius: "9px",
      color: "#000000",
    },

    "& button": {
      color: "#ffffff",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "400",
      textTransform: "capitalize",
      border: "none",
      backgroundColor: "none",
      marginRight: "1rem",
      height: "32px",
      width: "77px",
    },

    "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      borderRadius: "10px",
    },
    "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
      borderRadius: "10px",
    },
  },
}));

export default function ButtonAppBar(props) {
  const btnLinks = {
    home: config.CALENDAR_MEETING_URL,
    gryph: config.GRYPH_URL,
    search: config.PCA_SEARCH_URL,
    insights: config.INSIGHTS_URL,
    admin: config.GRYPH_ADMIN_URL,
  };

  const menuOptions = [
    {
      id: 1,
      label: "Admin",
    },
  ];

  const classes = useStyles();
  const [selButton, setSelButton] = useState("home");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const openAnchorEl = Boolean(moreAnchorEl);
  const [userInfo, setUserInfo] = React.useState(props?.userInfo);

  useEffect(() => {
    const path = window.location.href?.split("/");
    if (path?.length > 0) {
      const queryParams = path[3] && path[3]?.split("?"); //to get the page index
      if (queryParams?.length > 0 && queryParams[0]) {
        setSelButton(queryParams[0]);
      }
    }
  });

  React.useEffect(() => {
    setUserInfo(props.userInfo);
  }, [props.userInfo.userKey]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoreOptionClose = () => {
    setMoreAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.setItem("isLogoutClicked", JSON.stringify("true"));
    cookieUtils.redirectUserToMainLogin();
  };

  const onToggleButtonClickHandler = (event) => {
    const selectedValue = event.currentTarget.value;
    if (selectedValue === "insights") {
      salesAccelLogin();
    } else {
      setSelButton(selectedValue);
      const pageURL = selectedValue !== "" && btnLinks[selectedValue];
      const windowScope = "_self";
      window.open(pageURL, windowScope);
    }
  };

  const onMoreOptionButtonClickHandler = (event) => {
    const selectedValue = event.currentTarget?.innerText?.toLowerCase();
    if (selectedValue) {
      if (selectedValue === "log out") {
        handleLogOut();
      } else if (selectedValue === "insights") {
        salesAccelLogin();
      } else {
        setSelButton(selectedValue);
        const pageURL = selectedValue !== "" && btnLinks[selectedValue];
        const windowScope = "_self";
        window.open(pageURL, windowScope);
        handleMoreOptionClose();
      }
    }
  };

  const salesAccelLogin = () => {
    let xhr = new XMLHttpRequest();
    let result = "";
    let url = config.API_INSIGHTS_SESSION;
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        if (xhr.responseText) {
          result = JSON.parse(xhr.responseText);
          if (result.retCode === 0) {
            gotoSalesAccel(result);
          }
        }
      }
    };
    xhr.open("GET", url, true);
    xhr.withCredentials = true;
    xhr.send();
  };

  const gotoSalesAccel = (result) => {
    let xhr = new XMLHttpRequest();
    let req = `{"username": "${result.username}", "password": "${result.password}", "loginMode": 16}`;
    // switch to hash when ldap has been updated and SA no longer has its own login
    let token = "";
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        // should be 204
        token = xhr.getResponseHeader("x-mstr-authtoken");
      }
      window.open(result.loginUrl + "?x-mstr-authtoken=" + token);
    };

    xhr.open("POST", result.apiUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.withCredentials = true; // without this, JSESSIONID and iSession cookies won't be created and the subsequent login won't work

    xhr.send(req);
    return;
  };

  return (
    <AppBar position="sticky" className={classes.navbar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <img className={classes.logo} src={Logo} alt="gryphon.ai" />
        </div>
        <Box className={classes.toggleContentWrapper}>
          <ToggleButtonGroup
            exclusive
            className={classes.toggleButtonGroup}
            value={selButton}
            aria-label="Platform"
            onChange={onToggleButtonClickHandler}
          >
            <ToggleButton className={styles.tbtn_home} value="home">
              Home
            </ToggleButton>
            <ToggleButton className={styles.tbtn_gryph} value="gryph">
              Gryph
            </ToggleButton>
            <ToggleButton className={styles.tbtn_search} value="search">
              Search
            </ToggleButton>
            <ToggleButton className={styles.tbtn_insights} value="insights" style={{ width: "auto" }}>
              <>Insights</>
              <ExternalLinkIcon style={{ fill: "#FFFFFF", width: "16px", height: "16px", margin: "1px 0px 0px 2px" }} />
            </ToggleButton>
          </ToggleButtonGroup>
          <div className={styles.more_opt_btn}>
            <IconButton
              onClick={handleMoreOptionClick}
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              sx={{ borderRadius: "9px", padding: "4px" }}
            >
              <MoreVertIcon style={{ color: "white" }} />
            </IconButton>
            <Menu
              id="nav-options-menu"
              anchorEl={moreAnchorEl}
              open={openAnchorEl}
              onClose={handleMoreOptionClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItemWrapper className={styles.mitem_home} onClick={onMoreOptionButtonClickHandler}>
                Home
              </MenuItemWrapper>
              <MenuItemWrapper className={styles.mitem_gryph} onClick={onMoreOptionButtonClickHandler}>
                Gryph
              </MenuItemWrapper>
              <MenuItemWrapper className={styles.mitem_search} onClick={onMoreOptionButtonClickHandler}>
                Search
              </MenuItemWrapper>
              <MenuItemWrapper className={styles.mitem_insights} style={{ width: "auto" }} onClick={onMoreOptionButtonClickHandler}>
                <>Insights</>
                <ExternalLinkIcon style={{ fill: "#095CAA", width: "16px", height: "16px", margin: "0px 0px 0px 2px" }} />
              </MenuItemWrapper>
            </Menu>
          </div>
        </Box>
        <div
          className="appHeading"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "absolute",
            right: 10,
          }}
        >
          <div>
            <IconButton
              onClick={handleClick}
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              sx={{ borderRadius: "9px", padding: "8px" }}
            >
              <StyledTypography>{userInfo.firstName.toLowerCase() + " " + userInfo.lastName.toLowerCase()}</StyledTypography>
              <ProfileDropDownIcon style={{ marginLeft: "4px", color: "white" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {menuOptions?.map((item, index) => (
                <MenuItemWrapper key={`${index}_${item.label}`} onClick={onMoreOptionButtonClickHandler}>
                  {item.label}
                </MenuItemWrapper>
              ))}
              <Divider />
              <MenuItemWrapper key={"Logout"} onClick={onMoreOptionButtonClickHandler}>
                {"Log out"}
              </MenuItemWrapper>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const StyledTypography = withStyles({
  root: {
    textTransform: "capitalize",
    fontWeight: 400,
    fontSize: "16px",
    color: "white",
  },
})(Typography);

const MenuItemWrapper = styled(MenuItem)(() => ({
  color: "#095CAA",
}));
