import { makeStyles } from "@material-ui/core/styles";

const callRatingStyles = makeStyles((theme) => ({
  callRatingRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  participantText: {
    textAlign: "left",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  callRatingText: {
    width: "inherit",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    overflowY: "auto",
    maxHeight: "100px",
    marginLeft: "24px !important",
    marginRight: "8px !important",
  },
  callRatingsDocked: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  g1_score_box_details: {
    display: "block",
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: 400,
    fontStyle: "normal",
    color: "#FFFFFF",
    margin: "4px",
    lineHeight: "12px",
    letterSpacing: "0.1px",
  },
  g1_score_label: {
    marginRight: "4px",
    fontFamily: "Inter",
    fontSize: "14px",
    textAlign: "initial",
    textDecorationStyle: "dotted",
    textDecorationLine: "underline",
    color: "#495057",
    cursor: "context-menu",
    lineHeight: "24px",
  },
  drawer_rating_button: {
    borderRadius: "10px",
    outline: "0 !important",
    height: "52px",
  },
  rating_label: {
    marginRight: "4px",
    fontFamily: "Inter",
    fontSize: "14px",
    textAlign: "initial",
    color: "#495057",
    lineHeight: "24px",
  },
  call_rating_box: {
    width: 200,
    display: "block",
    marginBottom: "4px",
  },
  g1_score_box: {
    width: 200,
    display: "flex",
  },
  accordian_details: {
    paddingBottom: "0px",
    paddingTop: "0px",
    marginLeft: "25px",
  },
  rating_box_value: {
    ml: 1,
    mb: 0.6,
    marginLeft: "4px",
    fontSize: "12px",
    fontFamily: "Inter",
    color: "#495057",
  },
  scores_acc_summary: {
    padding: "0px !important",
    minHeight: "0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    background: "#FFFFFF",
    "&:hover": {
      background: "#F2F3F5",
      borderRadius: "2px",
    },
  },
  accordion_default_with_hover: {
    margin: "12px 16px 8px 16px !important",
    padding: "0px !important",
    minHeight: "0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    background: "#FFFFFF",
    "&:hover": {
      background: "#F2F3F5",
      borderRadius: "2px",
    },
  },
  drawer_label: {
    marginRight: "4px",
    fontFamily: "Inter",
    fontWeight: "600",
    color: "#495057",
  },
  rating_box: {
    width: "225px",
    display: "flex",
  },
  script_label: {
    marginRight: "4px",
    fontFamily: "Inter",
    fontSize: "14px",
    textAlign: "initial",
    color: "#495057",
    lineHeight: "24px",
  },
  scores_labels: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    fontStyle: "normal",
    color: "#495057",
    textAlign: "initial",
    margin: "4px",
    cursor: "default",
  },
  scores_acc_details: {
    padding: "0px 16px 0px !important",
  },
  rating_label_icon_span: {
    display: "inline-block",
    width: "18px",
    height: "18px",
    marginLeft: "4px",
    float: "right",
    textAlign: "center",
  },
  rating_label_span: {
    display: "inline-block",
    width: "80%",
    overflowWrap: "break-word",
  },
  script_label_button_span: {
    textDecorationLine: "underline",
    color: "#1971C2",
    cursor: "pointer",
    display: "inline-block",
    width: "80%",
    overflowWrap: "break-word",
  },
  scores_accordion: {
    width: "-webkit-fill-available",
  },
  svgExpandMoreIconBlue: {
    color: "#1971C2",
    backgroundColor: "white",
    borderRadius: "50%",
  },
  svgExpandMoreIconDefault: {
    backgroundColor: "white",
    borderRadius: "50%",
  },
}));

export default callRatingStyles;
