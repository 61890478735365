import React from "react";
import getUtteranceFlagCount from "../services/getUtteranceFlagCount.js";
import { Table, DatePicker } from "antd";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import getEditedFiles from "../services/getEditedFiles.js";
import getRetrainingInfo from "../services/getRetrainingInfo.js";
import { Select, MenuItem, FormControl, Button } from "@mui/material";
import styles from "./userAddedKPIs.module.css";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import performModelRetraining from "../services/performModelRetraining.js";
import { Redirect } from "react-router-dom";
import BootstrapTooltip from "../assets/components/BootstrapTooltip";
import Chip from "@mui/material/Chip";
import { getUtteranceFlagsSummary, removeUtteranceFlagsAndKPIs, saveUtteranceFlagsAndKPIs } from "../services/utteranceFlagService";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

export default function UserAddedKPIs(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const category = urlParams.get("category") ? decodeURIComponent(urlParams.get("category")) : "";
  const clientKey = urlParams.get("clientkey") ? decodeURIComponent(urlParams.get("clientkey")) : 0;
  const [userInfo, setUserInfo] = React.useState(props?.userInfo);
  const [setError] = React.useState({
    isError: false,
    msgError: null,
  });

  const [uak, setUAK] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [enableRetraining, setEnableRetraining] = React.useState(false);
  const [isClientAdmin, setIsClientAdmin] = React.useState(true);
  const [dateRange, setDateRange] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [KPIsWithCount, setKPIsWithCount] = React.useState([]);
  const [minimumDataPointsToRetrain, setMinimumDataPointsToRetrain] = React.useState(50);
  const [reRender, setRerender] = React.useState(false);
  const [trainingFlag, setTrainingFlag] = React.useState(false);

  const sortKPIs = (data) => {
    const dataArray = Object.entries(data).map(([key, value]) => ({ key, value }));
    dataArray.sort((a, b) => b.value - a.value);
    const sortedData = {};
    dataArray.forEach((item) => {
      sortedData[item.key] = item.value;
    });
    return sortedData;
  };

  const countsGreaterThanThreshold = (obj, minimumDataPointsToRetrain) => {
    for (const key in obj) {
      if (key in obj && obj[key] < minimumDataPointsToRetrain) {
        return false;
      }
    }
    return true;
  };

  React.useEffect(() => {
    (async () => {
      try {
        setUserInfo(props.userInfo);
        setIsClientAdmin(userInfo?.isAdmin);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to validate user token",
        });
      }
    })();
  }, [props.userInfo.userKey]);

  React.useEffect(() => {
    (async () => {
      try {
        let response = await getUtteranceFlagCount(category, clientKey);
        setUAK(response.data.UserAddedKPIs);
        setMinimumDataPointsToRetrain(response.data.DataPointsToRetrain);
        let retrainInfo = await getRetrainingInfo(category, clientKey);
        let dateToRender = [new Date(retrainInfo.data.Timestamp).toLocaleDateString("en-CA"), new Date().toLocaleDateString("en-CA")];
        setDateRange(dateToRender);
        setKPIsWithCount(sortKPIs(retrainInfo.data.FlagCounts));
        setTrainingFlag(retrainInfo.data.isTrainingFlag);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to get the UAKPIs",
        });
      }
    })();
  }, [clientKey, reRender]);

  React.useEffect(() => {
    (async () => {
      try {
        if (dateRange.length > 0) {
          let response = await getEditedFiles(category, clientKey, dateRange, currentPage);
          setItems(response.data);
        }
        setRerender(false);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to fetch data",
        });
      }
    })();
  }, [dateRange, currentPage, reRender]);

  React.useEffect(() => {
    const newObj = { ...KPIsWithCount };

    // Check if the "None" key exists in the dataset
    if ("None" in newObj) {
      // If it exists, delete it from the dataset
      delete newObj["None"];
    }
    if (Object.keys(newObj).length > 0) {
      const result = countsGreaterThanThreshold(newObj, minimumDataPointsToRetrain);
      if (result && trainingFlag === false) {
        setEnableRetraining(true);
      } else {
        setEnableRetraining(false);
      }
    }
  }, [KPIsWithCount, reRender, trainingFlag]);

  let flagOptions = [uak?.map((x) => x.FlagName)].flat(); //include None
  flagOptions.push("None");

  const handleFlagNameChange = async (value, record) => {
    if (value[value.length - 1] === "None") {
      for (const element of value) {
        if (element !== "None") {
          await handleDelete(element, record);
        }
      }
      value = ["None"];
    }
    if (value[0] === "None" && value.length > 1) {
      value.shift();
    }
    let utteranceFlagsReq = {
      clientKey: Number(clientKey),
      convID: record.fileId,
    };
    let UakData = await getUtteranceFlagsSummary(utteranceFlagsReq);
    let flagDetails = UakData.data.utteranceFlags.filter((details) => details.FlagName === value[value.length - 1] && details.Category === category);
    let createSelectedFlags = {
      UtteranceFlagIDArr: [],
      UserAddedKPIFlagIDArr: flagDetails,
    };
    let params = {
      ClientKey: Number(clientKey),
      ConversationID: record.fileId,
      UserKey: userInfo.userKey,
      MessageID: record.MessageID,
      Content: record.sentence?.Content,
      StartTime: parseInt(record.sentence?.StartTime.second),
      Category: category,
      RemoveFlags: { UtteranceFlagIDArr: [], UserAddedKPIFlagIDArr: [] },
      CreateFlags: createSelectedFlags,
    };
    await saveUtteranceFlagsAndKPIs(params);
    setRerender(true);
  };

  const performRetraining = async () => {
    // Here we are cooking the data as per flask service requirement
    let dataSet = {
      start_timestamp: new Date(dateRange[0])
        .toISOString()
        .replace(/T/, " ")
        .replace(/\.\d+Z$/, ""),
      end_timestamp: new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\.\d+Z$/, ""),
      trigger_type: "MANUAL",
      trigger_by: userInfo.emailAddress,
      ClientKey: clientKey,
      ModelCategory: category,
    };
    let response = await performModelRetraining(dataSet);
    if (response.message.includes("pipelineJobs")) {
      alert("Training job started sucessfully");
    } else {
      alert("Error in starting training");
    }
    window.location.href = `/Model-History?category=${category}&clientkey=${clientKey}`;
  };

  const handleDelete = async (value, record) => {
    let utteranceFlagsReq = {
      clientKey: Number(clientKey),
      convID: record.fileId,
    };
    let UakData = await getUtteranceFlagsSummary(utteranceFlagsReq);
    let flagDetails = UakData.data.utteranceFlagsSelected?.filter((details) => details.FlagName === value && details.MessageID === record.MessageID);
    let removeSelectedFlags = {
      UtteranceFlagIDArr: [],
      UserAddedKPIFlagIDArr: flagDetails,
    };
    let params = {
      ClientKey: Number(clientKey),
      UserKey: userInfo.userKey,
      ConversationID: record.fileId,
      MessageID: record.MessageID,
      Category: category,
      RemoveFlags: removeSelectedFlags,
    };
    await removeUtteranceFlagsAndKPIs(params);
    setRerender(true);
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "sno",
      width: "2%",
      render: (text, record, index) => <span key={index}>{index + 1}.</span>,
    },
    {
      title: "File",
      key: "actions",
      width: "2%",
      render: (text, record) => (
        <Link
          target="_blank"
          to={{
            pathname: `/${record.fileId}`,
          }}
        >
          <LaunchIcon style={{ fill: "#16a4e6" }} />
        </Link>
      ),
    },
    {
      title: "Speaker",
      dataIndex: ["sentence", "ParticipantRole"],
      width: "5%",
      render: (text, record) => {
        return record?.sentence?.ParticipantRole;
      },
    },
    {
      title: "Sentence",
      dataIndex: ["sentence", "Content"],
      width: "35%",
      render: (text, record) => {
        return record?.sentence?.Content;
      },
    },
    {
      title: "Prev KPI",
      key: "predictedKpiByModel",
      width: "10%",
      render: (text, record) => {
        if (record?.predictedKpiByModel?.length > 0) {
          const flagNames = record?.predictedKpiByModel?.map((moment) => moment.FlagName).join(", ");
          return flagNames;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Curr KPI",
      key: "FlagName",
      width: "10%",
      render: (text, record) => {
        const flagNames = record?.sentence?.UserAdded_KPI[`${category}`]?.map((moment) => moment.FlagName).join(", ");
        return flagNames;
      },
    },
    {
      title: "Edit",
      key: "FlagNameEdit",
      render: (text, record) => {
        let flags = record?.sentence?.UserAdded_KPI[`${category}`]?.map((x) => x.FlagName);
        let defaultValue = flags?.length > 1 ? [] : flags;
        return (
          <FormControl variant="standard" sx={{ m: 1 }} size="small">
            <Select
              key={record.MessageID} // Add a key based on the category
              value={flags?.length > 0 ? flags : []}
              defaultValue={defaultValue}
              style={{ width: "fit-content", fontSize: "small" }}
              multiple
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={value !== "None" ? () => handleDelete(value, record) : undefined}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </div>
              )}
              onChange={(event) => handleFlagNameChange(event.target.value, record)}
            >
              {flagOptions?.map((flagName) => (
                <MenuItem key={flagName} value={flagName}>
                  {flagName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
  ];

  if (userInfo.clientKey !== 0) {
    if (!isClientAdmin || userInfo.clientKey !== 1) {
      return <Redirect to={"/files"}></Redirect>;
    }
  }

  return (
    <>
      <Link
        to={{
          pathname: "/UtteranceFlags",
          search: `${window.location.search}`,
        }}
      >
        <Button variant="outlined" style={{ position: "absolute", left: 0 }}>
          <strong>Back</strong>
        </Button>
      </Link>
      <Link
        to={{
          pathname: "/Model-History",
          search: `${window.location.search}`,
        }}
      >
        <Button variant="outlined" style={{ position: "absolute", left: "72px" }}>
          <strong>Model History</strong>
        </Button>
      </Link>
      <div style={{ marginTop: 15, display: "block" }}>
        <strong>Records for {category} UAK</strong>
      </div>
      <>
        <div className={styles.UAKwithCountContainer}>
          {Object.entries(KPIsWithCount).map(([key, value]) => (
            <div
              key={key}
              className={
                key === "None"
                  ? styles.UAKForNone // Apply a different class for "None"
                  : value >= minimumDataPointsToRetrain
                  ? styles.UAKwithCountGreen
                  : styles.UAKwithCountRed
              }
            >
              {key}:{value}
            </div>
          ))}
        </div>
        <Table
          columns={columns}
          dataSource={items.dataArray}
          bordered
          title={() => (
            <div className={styles.dateHeader}>
              {dateRange.length > 0 ? (
                <RangePicker
                  bordered={false}
                  // onChange={onDateChange}
                  defaultValue={[dayjs(dateRange[0], dateFormat), dayjs(dateRange[1], dateFormat)]}
                  disabled={[true, true]}
                  format={dateFormat}
                />
              ) : null}
            </div>
          )}
          pagination={{
            total: items.totalCount,
            simple: true,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
          footer={() => (
            <>
              {enableRetraining ? (
                <Button variant="outlined" disabled={!enableRetraining} onClick={performRetraining}>
                  <strong>Train Model</strong>
                </Button>
              ) : (
                <BootstrapTooltip
                  title={`Each KPI must have ${minimumDataPointsToRetrain} data points & there should be no active retraining job`}
                  placement="top"
                  arrow
                >
                  <span>
                    <Button variant="outlined" disabled={true}>
                      <strong>Train Model</strong>
                    </Button>
                  </span>
                </BootstrapTooltip>
              )}
            </>
          )}
        />
      </>
    </>
  );
}
