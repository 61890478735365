import { DateTime, Duration } from "luxon";
import { validateExternalAccessTokenAPI, getUserSessionAPI, extendUserSessionAPI, endUserSessionAPI } from "../services/auth";
import * as constants from "./constants";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import React from "react";
import config from "../config/config";

const errResponse = {
  err: true,
  errCode: 401,
  errSourceURL: window.location.href,
};

export const setCookie = (name = "", val = "") => {
  // setting it to expire in 30 mins
  const date = DateTime.local().plus(Duration.fromObject({ minutes: 30 }));
  const value = val;

  document.cookie = name + "=" + value + "; expires=" + date.toHTTP() + "; path=/";
};

export const getCookie = (name = "") => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};

export const deleteCookie = (name = "") => {
  // set it to expire in -1 days
  const date = DateTime.local().plus(Duration.fromObject({ days: -1 }));

  document.cookie = name + "=; expires=" + date.toHTTP() + "; path=/";
};

export const fetchActiveUserSession = async () => {
  let activeUser = {};
  // get external token if available in query params
  let externalToken = queryString.parse(window.location.search).externalToken;
  if (externalToken != null && externalToken !== "") {
    // when external access token available in URL - validate external token
    let res = await validateExternalAccessTokenAPI({ token: externalToken });
    if (res?.retCode === "0" && res?.retMsg === "success") {
      // token is valid;
      res.isExternalToken = true;
      // add default values
      res.userKey = 0;
      res.clientKey = 0;
      res.firstName = "";
      res.lastName = "";
      res.emailAddress = "";
      res.status = "";
      res.role = "";
      res.officeKey = 0;
      res.office = "";
      res.goeLicense = "";
      res.isAdmin = false;
      activeUser = res;
    } else {
      // when retCode is 1; means invalid; so redirect to error page
      redirectUserToErrorPage();
    }
  } else {
    let userSessionRes = await getUserSessionAPI();
    if (userSessionRes?.retCode === 0) {
      let gryphProduct = userSessionRes?.products?.find((p) => p.product === "Gryph");
      activeUser = {
        clientKey: userSessionRes?.clientKey,
        emailAddress: userSessionRes?.emailAddress,
        isExternalToken: false,
        goeLicense: gryphProduct?.license ? gryphProduct.license : "",
        expires: userSessionRes?.expires,
        sessionTimeout: userSessionRes?.sessionTimeout,
      };
      if (userSessionRes?.users?.length > 0) {
        let adminUser = userSessionRes?.users?.find((user) => user.userType === constants.ADMIN_USERTYPE);
        let agentUser = userSessionRes?.users?.find((user) => user.userType === constants.AGENT_USERTYPE);
        if (adminUser) {
          activeUser = { ...activeUser, ...adminUser, isAdmin: true };
        } else if (agentUser) {
          activeUser = { ...activeUser, ...agentUser, isAdmin: false };
        } else {
          let user = userSessionRes?.users[0];
          activeUser = { ...activeUser, ...user, isAdmin: false };
        }
      } else {
        // when users is empty; means invalid; so redirect to login
        redirectUserToErrorPage();
      }
    } else {
      // when no user session found
      redirectUserToLogin();
    }
  }
  return activeUser;
};

export const redirectUserToLogin = () => {
  const redirectURL = config.CALENDAR_REDIRECT_LOGIN_URL + "?goto=" + window.location.href;
  window.location.replace(redirectURL);
};

export const redirectUserToMainLogin = async () => {
  await endUserSession();
  const redirectURL = config.CALENDAR_REDIRECT_LOGIN_URL + "?goto=" + window.location.href;
  window.location.replace(redirectURL);
};

export const redirectUserToErrorPage = () => {
  <Redirect to="*" errResponse={errResponse}></Redirect>;
};

export const extendUserSession = async () => {
  let res = await extendUserSessionAPI();
  if (res?.retCode !== 0) {
    // when no user session found
    redirectUserToLogin();
  }
};

export const endUserSession = async () => {
  await endUserSessionAPI();
};
