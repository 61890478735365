import React from "react";
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import getModelCategory from "../services/getModelCategory";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import styles from "./userAddedKPIs.module.css";
import getUtteranceFlagCount from "../services/getUtteranceFlagCount.js";
import config from "../../src/config/config.js";
import queryString from "query-string";

export default function Choice(props) {
  const [userInfo, setUserInfo] = React.useState(props?.userInfo);
  const [isClientAdmin, setIsClientAdmin] = React.useState(true);
  const [modelsList, setModelsList] = React.useState([]);
  const [selectedClientKey, setSelectedClientKey] = React.useState("");
  const [selectedModelCategory, setSelectedModelCategory] = React.useState("");
  const [userAddedKpiExists, setUserAddedKpiExists] = React.useState(false);

  const [error, setError] = React.useState({
    isError: false,
    msgError: null,
  });

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const debounceOnChange = debounce((value) => {
    setSelectedClientKey(value);
  }, 1200); // 1200 milliseconds debounce delay

  React.useEffect(() => {
    (async () => {
      try {
        setUserInfo(props.userInfo);
        setIsClientAdmin(userInfo.isAdmin);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to validate user token",
        });
      }
    })();
  }, [props.userInfo.userKey]);

  React.useEffect(() => {
    (async () => {
      try {
        if (userInfo.clientKey !== 0) {
          let response = await getModelCategory(userInfo.clientKey);
          if (response.data !== null) {
            setModelsList(response.data);
            setError({
              isError: false,
              msgError: null,
            });
          } else {
            setError({
              isError: false,
              msgError: `No Category is available for ClientKey ${userInfo.clientKey}`,
            });
          }
        }
        if (selectedClientKey === "0") {
          setModelsList([]);
        }
        if (selectedClientKey !== "" && selectedClientKey !== "0") {
          setSelectedModelCategory("");
          let response = await getModelCategory(selectedClientKey);
          if (response.data !== null) {
            setModelsList(response.data);
            setError({
              isError: false,
              msgError: null,
            });
          } else {
            setError({
              isError: false,
              msgError: `No Category is available for ClientKey ${selectedClientKey}`,
            });
          }
        }
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to fetch models list for selected client",
        });
      }
    })();
  }, [userInfo.clientKey, selectedClientKey]);

  React.useEffect(() => {
    (async () => {
      try {
        if (selectedModelCategory !== "") {
          let ck = selectedClientKey === "" ? userInfo.clientKey : selectedClientKey;
          let response = await getUtteranceFlagCount(selectedModelCategory, ck);
          let flag = response.data.UserAddedKPIs;
          if (flag.length > 0) {
            setUserAddedKpiExists(true);
          } else {
            setUserAddedKpiExists(false);
          }
        }
      } catch (err) {
        setError({
          isError: false,
          msgError: "",
        });
      }
    })();
  }, [userInfo.clientKey, selectedModelCategory, selectedClientKey]);

  const handleModelCategoryChange = (event) => {
    setSelectedModelCategory(event.target.value);
  };

  const choiceRedirect = (option) => {
    let redirectURL = "#";
    const parsedParams = queryString.parse(window.location.search);
    parsedParams["category"] = encodeURIComponent(selectedModelCategory);
    parsedParams["clientkey"] = selectedClientKey !== "" ? encodeURIComponent(selectedClientKey) : encodeURIComponent(userInfo.clientKey);
    const newParams = queryString.stringify(parsedParams);
    if (option !== null) {
      redirectURL = config.PCA_CLIENT_URL + `${option}?${newParams}`;
    }
    window.location.replace(redirectURL);
  };

  if (userInfo.clientKey !== 0) {
    if (!isClientAdmin || userInfo.clientKey !== 1) {
      return <Redirect to={"/files"}></Redirect>;
    }
  }

  return (
    <>
      {error.isError ? (
        <div className={styles.errorMsg}>{error.msgError}</div>
      ) : (
        <div className={styles.mainContainer}>
          <div>
            <Link
              to={{
                pathname: "/UAK-Configurations",
                search: "",
              }}
            >
              <Button variant="outlined">Configurations</Button>
            </Link>
            <div>
              <br></br>
              <>
                <FormControl sx={{ m: 1, width: 100 }}>
                  <TextField
                    id="demo-simple-select"
                    label="ClientKey"
                    variant="outlined"
                    defaultValue={1}
                    type="number"
                    onChange={(e) => debounceOnChange(e.target.value)}
                  />
                </FormControl>
                <br></br>
                <br></br>
              </>

              {error.msgError ? (
                <div className={styles.errorMsg}>{error.msgError}</div>
              ) : (
                <>
                  {selectedClientKey === "0" ? (
                    <div>No Category is available for ClientKey 0</div>
                  ) : (
                    <>
                      <FormControl sx={{ m: 2, minWidth: 200 }}>
                        <InputLabel id="model-category-select">Category</InputLabel>
                        <Select
                          labelId="model-category-select"
                          id="model-category"
                          label="Category"
                          value={selectedModelCategory}
                          onChange={handleModelCategoryChange}
                        >
                          {modelsList.map((options, i) => (
                            <MenuItem key={i} value={options}>
                              {options}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}

                  {selectedModelCategory === "" ? null : (
                    <div>
                      <Button variant="outlined" className={styles.redirectButtons} onClick={() => choiceRedirect("UtteranceFlags")}>
                        Utterance Flags
                      </Button>
                      <Button
                        variant="outlined"
                        className={styles.redirectButtons}
                        disabled={!userAddedKpiExists}
                        onClick={() => choiceRedirect("UserAddedKPIs")}
                      >
                        User Added KPIs
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
