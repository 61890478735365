import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const StyledIconButton = withStyles(() => ({
  root: {
    padding: "0px",
    borderRadius: "12px",
  },
}))(IconButton);

export default StyledIconButton;
