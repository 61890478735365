import * as am4core from "@amcharts/amcharts4/core";
import { DateTime } from "luxon";
import { ReactComponent as ArrowDownSVG } from "./svgs/gn-icon_arrowDown.svg";
import { ReactComponent as DoubleDownSVG } from "./svgs/gn-icon_doubleDown.svg";
import { ReactComponent as ArrowUPSVG } from "./svgs/gn-icon_arrowUp.svg";
import { ReactComponent as DoubleUPSVG } from "./svgs/gn-icon_doubleUp.svg";
import React from "react";
import queryString from "query-string";
import { VeryNegativeIcon, NegativeIcon, PositiveIcon } from "../assets/components/threshold_icons/ThresholdIcons";
import { NeutralSuccessIcon, FailureIcon } from "../assets/components/compliance_icons/ComplianceIcons";
import { ReactComponent as GNComplianceDisabled } from "../assets/svgs/gn-compliance-disabled-status.svg";
import { ReactComponent as GNCompliancePass } from "../assets/svgs/gn-compliance-pass-status.svg";
import { ReactComponent as GNComplianceFail } from "../assets/svgs/gn-compliance-fail-status.svg";
import { ReactComponent as GNComplianceTrigger } from "../assets/svgs/gn-compliance-trigger-status.svg";
import BootstrapTooltip from "./components/BootstrapTooltip";
import StyledIconButton from "./components/StyledIconButton/StyledIconButton";
// Variables

export const hideKPI = "None";
export const UNKNOWN_SPEAKER = "Unknown";
export const HOST = "HOST";
export const HUMAN_AGENT = "HUMAN_AGENT";
export const END_USER = "END_USER";
export const AGENT = "AGENT";
export const CUSTOMER = "CUSTOMER";
export const DUMMY = "KPI";
export const GRYPHON = "Gryphon";
export const SNIP_PARAM = "_snip_";
export const EDIT_PARAM = "ie";
export const MEDDIC_PARAM = "im";
export const USERKEY_PARAM = "uk";
export const USEREMAIL_PARAM = "ue";
export const START_TIME_PARAM = "st";
export const END_TIME_PARAM = "et";
export const AGENT_ROLE = "agent";
export const CLIENT_ADMIN_ROLE = "Client Administrator";
export const AGENT_USERTYPE = "agent";
export const ADMIN_USERTYPE = "admin";
export const ACTIVE = "active";
export const REGEX_HHMMSS = "^(?:[01]\\d|2[0123]):(?:[012345]\\d):(?:[012345]\\d)$";
export const REGEX_MMSS = "^(?:[012345]\\d|2[0123]):(?:[012345]\\d)$";
export const REGEX_SNIPPET_NAME = "^([^`'\"]*)$";
export const REGEX_ONLY_DIGITS = "^[0-9]+$";
export const FORMAT_HHMMSS = "##:##:##";
export const FORMAT_MMSS = "##:##";
export const PROCESSING_COMPLETE = "Complete";
export const NOTE_NO_TRANSCRIPT = "No sentiment data available";
export const NOTE_UNAVAILABLE = "Analysis in Progress";
export const MSG_UNAVAILABLE = "The transcript and analysis of this call are in progress. Please check back in 15 minutes.";
export const MSG_NO_TRANSCRIPT =
  "The transcript and analysis of this call are not available. To listen to the audio of the call, please use the player above.";
export const T_NEUTRAL = 0;
export const T_POSITIVE = 0.2;
export const T_NEGATIVE = -0.3;
export const T_VERY_POSITIVE = 0.8;
export const T_VERY_NEGATIVE = -0.7;
export const COLOR_HOST = am4core.color("#1971C2");
export const COLOR_PARTICIPANT = am4core.color("#087F5B");
export const COLOR_TJ_GREEN = am4core.color("#0D8244");
export const COLOR_TJ_LIGHT_GREEN = am4core.color("#4BC957");
export const COLOR_TJ_YELLOW = am4core.color("#1971C2");
export const COLOR_TJ_ORANGE = am4core.color("#FD9A00");
export const COLOR_TJ_RED = am4core.color("#C92A2A");
export const KPIS = "Key Moments";
export const MEDDICC_KPIS = "MEDDICC Key Moments";
export const CUSTOMER_KPIS = "Customer Key Moments";
export const MEDDICC = "MEDDICC";
export const REMOVE_CATEGORY = "Remove";
export const OTHER_CATEGORY = "Other";
export const INFORMATIONAL_CATEGORY = "Informational";
export const COMPLIMENTARY_CATEGORY = "Complimentary";
export const IMPROVEMENT_CATEGORY = "Suggested Improvement";
export const COOKIE_USER = "activeUserPCA";
export const DEFAULT_NO_TRANSCRIPT_OBJECT = {
  MediaIndex: 0,
  MessageID: "PCA_CUSTOM",
  LanguageCode: "en-US",
  Content: "Transcript is not available for this call.",
  StartTime: { nanosecond: 0, second: 0 },
  EndTime: { nanosecond: 0, second: 0 },
  ParticipantID: "",
  ParticipantRole: "HOST",
  CustomRole: "HUMAN_AGENT",
  KPI: [{ value: "None", KpiID: "kp0" }],
  MEDDIC_KPI: [{ value: "None", KpiID: "mkp0" }],
  SentimentAnalysis: { score: 0, magnitude: 0 },
};
export const ERROR_MSG_LOADING = "Error loading call, please contact Gryphon help desk at 866-366-6822 or email to support@gryphon.zendesk.com";
export const ERROR_MSG_UNAUTHORIZED = "You are not authorized to view this call";
export const ERROR_MSG_NOT_PROCESSED = "Call analysis in progress, please try again later";
export const ERROR_NO_RECORDING_AVAILALE = "Recording is not available for this call";
export const POLARITY_POSITIVE = "positive";
export const POLARITY_NEGATIVE = "negative";
export const POLARITY_NEUTRAL = "neutral";
export const AMCHARTS_LICENSE = "CH271010443";
export const G1_SCORE_THRESHOLD = {
  low: 25,
  mid: 50,
  high: 75,
};
// Functions

export const extractFileId = (routeURL) => {
  return routeURL.split("+")[0];
};

export const extractBool = (queryString, param) => {
  let boolToReturn = false;
  if (queryString.indexOf(param) > 0) {
    queryString = queryString.split(param)[1];
    if (queryString.indexOf("+") < 0) {
      queryString.concat("+");
    }
    boolToReturn = queryString.split("+")[0] === "true" ? true : false;
  }
  return boolToReturn;
};

export const extractAgentEmail = (queryStringObj, param) => {
  let agentEmail = containsParameter(queryStringObj, param) ? queryStringObj[param] : "agent@gryphon.com";
  if (localStorage.getItem("user") != null) {
    localStorage.setItem("user", agentEmail);
  } // TODO: check if localStorage works here.
  return agentEmail;
};

export const extractParameter = (queryString, param) => {
  let numberToReturn = 0;
  if (queryString && queryString.indexOf(param) > 0) {
    queryString = queryString.split(param)[1];
    if (queryString.indexOf("+") < 0) {
      queryString.concat("+");
    }
    numberToReturn = parseInt(queryString.split("+")[0]);
  }
  return numberToReturn;
};

export const containsParameter = (queryStringObj, param) => {
  return Object.keys(queryStringObj).includes(param);
};

export const extractParameterByType = (queryStringObj, param, type) => {
  switch (type) {
    case "str":
      return containsParameter(queryStringObj, param) ? queryStringObj[param] : "";
    case "int":
      return containsParameter(queryStringObj, param) ? parseInt(queryStringObj[param]) : 0;
    case "bool":
      return containsParameter(queryStringObj, param) ? (queryStringObj[param] === "true" ? true : false) : false;
    default:
      return containsParameter(queryStringObj, param) ? queryStringObj[param] : null;
  }
};

export const checkIfClientAdminRole = (data) => {
  if (data.role === CLIENT_ADMIN_ROLE && data.status === ACTIVE) {
    return true;
  } else {
    return false;
  }
};

export const checkIfAdminUserType = (data) => {
  return data.UserType === ADMIN_USERTYPE && data.Status === ACTIVE;
};

export const checkIfHost = (role) => {
  return role === AGENT || role === HUMAN_AGENT || role === HOST;
};

export const checkIfParticipant = (role) => {
  return role === CUSTOMER || role === END_USER;
};

export const toHHMMSS = (timestamp, isLongCall) => {
  var hours = Math.floor(timestamp / 60 / 60);
  var minutes = Math.floor(timestamp / 60) - hours * 60;
  var seconds = timestamp % 60;
  if (isLongCall) {
    return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
  }
  return minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
};

export const toStringHHMMSS = (timestamp, isLongCall) => {
  var hours = Math.floor(timestamp / 60 / 60);
  var minutes = Math.floor(timestamp / 60) - hours * 60;
  var seconds = timestamp % 60;
  if (isLongCall) {
    return hours.toString().padStart(2, "0") + "h " + minutes.toString().padStart(2, "0") + "m " + seconds.toString().padStart(2, "0") + "s";
  }
  return minutes.toString().padStart(2, "0") + "m " + seconds.toString().padStart(2, "0") + "s";
};

export const toSeconds = (time) => {
  let hms = time.toString();
  if (hms.length < 3) {
    // hms = hms;
  } else if (hms.length < 6) {
    const a = hms.split(":");
    hms = +a[0] * 60 + +a[1];
  } else {
    const a = hms.split(":");
    hms = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  }
  return hms;
};

export const stringToHHMMSS = (time, isLongCall, inputFlag, duration) => {
  let hms = time.toString();

  if (!isLongCall) {
    if (hms.length < 1) {
      if (inputFlag === START_TIME_PARAM) {
        let res = "00:00";
        hms = res;
      } else {
        let res = toHHMMSS(duration, isLongCall);
        hms = res;
      }
    } else if (hms.length < 2) {
      let res = hms.charAt(0) + "0:00";
      hms = res;
    } else if (hms.length < 3) {
      let res = hms.charAt(0) + hms.charAt(1) + ":00";
      hms = res;
    } else if (hms.length < 4) {
      let res = hms.charAt(0) + hms.charAt(1) + ":" + hms.charAt(2) + "0";
      hms = res;
    } else {
      let res = hms.charAt(0) + hms.charAt(1) + ":" + hms.charAt(2) + hms.charAt(3);
      hms = res;
    }
  } else {
    if (hms.length < 1) {
      if (inputFlag === START_TIME_PARAM) {
        let res = "00:00:00";
        hms = res;
      } else {
        let res = toHHMMSS(duration, isLongCall);
        hms = res;
      }
    } else if (hms.length < 2) {
      let res = hms.charAt(0) + "0:00:00";
      hms = res;
    } else if (hms.length < 3) {
      let res = hms.charAt(0) + hms.charAt(1) + ":00:00";
      hms = res;
    } else if (hms.length < 4) {
      let res = hms.charAt(0) + hms.charAt(1) + ":" + hms.charAt(2) + "0:00";
      hms = res;
    } else if (hms.length < 5) {
      let res = hms.charAt(0) + hms.charAt(1) + ":" + hms.charAt(2) + hms.charAt(3) + ":00";
      hms = res;
    } else if (hms.length < 6) {
      let res = hms.charAt(0) + hms.charAt(1) + ":" + hms.charAt(2) + hms.charAt(3) + ":" + hms.charAt(4) + "0";
      hms = res;
    } else {
      let res = hms.charAt(0) + hms.charAt(1) + ":" + hms.charAt(2) + hms.charAt(3) + ":" + hms.charAt(4) + hms.charAt(5);
      hms = res;
    }
  }
  return hms;
};

const getParticipantRole = (item) => {
  return updatedSpeaker(item.ParticipantRole);
};

const getParticipantColor = (item) => {
  let speaker = updatedSpeaker(item.ParticipantRole);
  let color = checkIfHost(speaker) ? COLOR_HOST : COLOR_PARTICIPANT;
  return color;
};

const sortChartDataForVideoMeeting = (chartData, participants) => {
  let sortedChartData = [];
  let participantSentencesObject = [];
  let hostParticipantID;
  participants.forEach((p) => {
    if (p.custom_role === "HUMAN_AGENT") {
      hostParticipantID = p.id;
    }
  });
  for (const idx in chartData) {
    chartData[idx].forEach((data) => {
      if (!participantSentencesObject[idx]) {
        participantSentencesObject[idx] = {};
      }
      if (Object.prototype.hasOwnProperty.call(participantSentencesObject[idx], data.ParticipantId)) {
        participantSentencesObject[idx][data.ParticipantId].push(data);
      } else {
        participantSentencesObject[idx][data.ParticipantId] = [];
        participantSentencesObject[idx][data.ParticipantId].push(data);
      }
    });
  }
  // Adding dumming HOST information for generating chartData
  if (participantSentencesObject.length > 0) {
    if (!participantSentencesObject[0][hostParticipantID]) {
      participantSentencesObject[0][hostParticipantID] = [];
      participantSentencesObject[0][hostParticipantID].push({ ParticipantRole: AGENT, ParticipantId: hostParticipantID, KPI: [], MEDDIC_KPI: [] });
    }
  }
  for (const idx in chartData) {
    if (
      Object.prototype.hasOwnProperty.call(participantSentencesObject[idx], hostParticipantID) &&
      participantSentencesObject[idx][hostParticipantID].length > 0
    )
      if (!sortedChartData[idx]) {
        sortedChartData[idx] = [];
      }
    sortedChartData[idx].push(...participantSentencesObject[idx][hostParticipantID]);
    let participantIds = Object.keys(participantSentencesObject[idx]);
    // eslint-disable-next-line no-loop-func
    participantIds.forEach((participantID) => {
      if (participantID !== hostParticipantID) {
        sortedChartData[idx].push(...participantSentencesObject[idx][participantID]);
      }
      if (participantSentencesObject[idx][participantID].length < 1) {
        sortedChartData[idx].push({
          ParticipantRole: CUSTOMER,
          ParticipantId: participantID,
          KPI: [],
          MEDDIC_KPI: [],
        });
      }
    });
  }
  return sortedChartData;
};

export const transformForChartData = (sentences, duration, participants, mediaType, recordings) => {
  let isVideo = mediaType === "video" ? true : false;
  let numberOfRecording = recordings || isVideo ? recordings.length : 1;
  let chartData = [];
  let dummyAgent = {
    ParticipantRole: AGENT,
    KPI: [],
    MEDDIC_KPI: [],
  };
  let dummyCustomer = {
    ParticipantRole: CUSTOMER,
    KPI: [],
    MEDDIC_KPI: [],
  };
  let modifiedSentences = [];
  if (isVideo) {
    sentences.forEach((item) => {
      if (recordings.length > 0) {
        if (Array.isArray(modifiedSentences[item.MediaIndex])) {
          modifiedSentences[item.MediaIndex].push(item);
        } else {
          modifiedSentences[item.MediaIndex] = [item];
        }
      }
    });
  } else {
    modifiedSentences = [sentences];
  }

  if (sentences.length) {
    let lastSentenceIndex = isVideo
      ? sentences.indexOf(sentences.filter((item) => item.MediaIndex === numberOfRecording - 1).at(-1))
      : sentences.length - 1;
    sentences[lastSentenceIndex].EndTime.second = duration;
    chartData = modifiedSentences.map((sentenceArray) => {
      let chartDataArray = [];
      sentenceArray.forEach((item, index) => {
        let resKPI = calculateStartTimeForKPI(item.StartTime.second, item.EndTime.second, item.KPI, item.MEDDIC_KPI);
        for (let i = index + 1; i < sentenceArray.length; i++) {
          let currentParticipant = item.ParticipantID;
          let currentTime = item.EndTime.second;
          let nextParticipant = sentenceArray[i].ParticipantID;
          let nextTime = sentenceArray[i].StartTime.second;
          if (currentParticipant === nextParticipant) {
            // item.isLast = false;
            item.isLast = currentTime === nextTime ? false : true;
            item.isFirst = index !== 0 && !sentenceArray[index - 1].isLast ? false : true;
            break;
          } else {
            item.isLast = true;
            item.isFirst = index !== 0 && !sentenceArray[index - 1].isLast ? false : true;
            break;
          }
        }
        if (index === sentenceArray.length - 1) {
          if (item.ParticipantID === sentenceArray[index - 1]?.ParticipantID) {
            item.isFirst = item?.StartTime?.second === sentenceArray[index - 1]?.EndTime?.second ? false : true;
          } else {
            item.isFirst = true;
          }
          item.isLast = true;
        }
        // round of sentiment analysis to 2 decimal places
        Object.entries(item.SentimentAnalysis).forEach(([key, value]) => {
          if (typeof value === "number") {
            item.SentimentAnalysis[key] = +value.toFixed(2); // 1.9999 -> 2
          }
        });
        if (isVideo) {
          if (item.ParticipantID) {
            chartDataArray.push({
              StartTime: item.StartTime.second,
              EndTime: item.EndTime.second,
              SentimentAnalysis: item.SentimentAnalysis,
              color: item.SentimentAnalysis.score
                ? returnColorForChart(parseFloat(item.SentimentAnalysis.score.toFixed(1)))
                : returnColorForChart(parseFloat((0).toFixed(1))), // rounding the score to one decimal point
              ParticipantId: getParticipantId(participants, item.ParticipantID),
              ParticipantRole: getParticipantRole(item, isVideo),
              ParticipantColor: getParticipantColor(item, isVideo),
              ParticipantName: getParticipant(participants, item.ParticipantID, item.ParticipantRole),
              KPI: resKPI?.filter((obj) => {
                return obj.Type === "kpis";
              }),
              KPI_Confidence: item.KPI_Confidence,
              MEDDIC_KPI: resKPI?.filter((obj) => {
                return obj.Type === "mkpis";
              }),
              isFirst: item.isFirst,
              isLast: item.isLast,
              // Left
              cornerRadiusTopLeft: item.isFirst ? 10 : 0,
              cornerRadiusBottomLeft: item.isFirst ? 10 : 0,
              // Right
              cornerRadiusBottomRight: item.isLast ? 10 : 0,
              cornerRadiusTopRight: item.isLast ? 10 : 0,
              MediaIndex: item.MediaIndex,
            });
          }
        } else {
          chartDataArray.push({
            StartTime: item.StartTime.second,
            EndTime: item.EndTime.second,
            SentimentAnalysis: item.SentimentAnalysis,
            color: item.SentimentAnalysis.score
              ? returnColorForChart(parseFloat(item.SentimentAnalysis.score.toFixed(1)))
              : returnColorForChart(parseFloat((0).toFixed(1))), // rounding the score to one decimal point
            ParticipantId: getParticipantId(participants, item.ParticipantID),
            ParticipantRole: getParticipantRole(item, isVideo),
            ParticipantColor: getParticipantColor(item, isVideo),
            ParticipantName: getParticipant(participants, item.ParticipantID, item.ParticipantRole),
            KPI: resKPI?.filter((obj) => {
              return obj.Type === "kpis";
            }),
            KPI_Confidence: item.KPI_Confidence,
            MEDDIC_KPI: resKPI?.filter((obj) => {
              return obj.Type === "mkpis";
            }),
            isFirst: item.isFirst,
            isLast: item.isLast,
            // Left
            cornerRadiusTopLeft: item.isFirst ? 10 : 0,
            cornerRadiusBottomLeft: item.isFirst ? 10 : 0,
            // Right
            cornerRadiusBottomRight: item.isLast ? 10 : 0,
            cornerRadiusTopRight: item.isLast ? 10 : 0,
          });
        }
      });
      if (chartDataArray.length > 0) chartDataArray[0].StartTime = 0;
      return chartDataArray;
    });
  }
  //Sort Data
  if (!isVideo) {
    for (const idx in chartData) {
      if (!chartData[idx].some((e) => e.ParticipantRole === CUSTOMER) && !chartData[idx].some((e) => e.ParticipantRole === AGENT)) {
        chartData[idx].push(dummyCustomer);
        chartData[idx].push(dummyAgent);
      } else if (!chartData[idx].some((e) => e.ParticipantRole === CUSTOMER)) {
        chartData[idx].push(dummyCustomer);
      } else if (!chartData[idx].some((e) => e.ParticipantRole === AGENT)) {
        chartData[idx].push(dummyAgent);
      }
    }
    // sort array to display AGENT then CUSTOMER
  } else {
    chartData = sortChartDataForVideoMeeting(chartData, participants);
  }
  return chartData; // sentenceLevel Data.
};

export const getFraction = (startTime, endTime, arrlength) => {
  let sDuration = endTime - startTime;
  if (arrlength * 0.5 < sDuration) {
    return 0.7;
  } else if (arrlength * 0.4 < sDuration) {
    return 0.6;
  } else {
    return 0.5;
  }
};

export const calculateStartTimeForKPI = (startTime, endTime, salesKPIs, meddicKPIs) => {
  let resultKPI = [];
  salesKPIs?.forEach((element) => {
    if (element.value !== hideKPI) resultKPI.push({ KpiID: element.KpiID, StartTime: 0, Value: element.value, Type: "kpis" });
  });
  meddicKPIs?.forEach((element) => {
    if (element.value !== hideKPI) resultKPI.push({ KpiID: element.KpiID, StartTime: 0, Value: element.value, Type: "mkpis" });
  });
  if (resultKPI.length === 0) {
    return resultKPI;
  }
  let startIndex = 0;
  let endIndex = resultKPI.length;
  let midIndex = Math.floor((endIndex - startIndex) / 2);
  let fraction = getFraction(startTime, endTime, resultKPI.length);
  let sentenceDuration = endTime - startTime;
  let midDuration = sentenceDuration / 2;
  resultKPI[midIndex].StartTime = startTime + midDuration;
  if (resultKPI.length === 1) {
    return resultKPI;
  }
  recursiveUpdateForKPI(resultKPI, startIndex, midIndex, fraction, true);
  recursiveUpdateForKPI(resultKPI, midIndex + 1, endIndex, fraction, false);
  return resultKPI;
};

export const recursiveUpdateForKPI = (resultKPI, istart, iend, fraction, isFirstSegment) => {
  if (istart >= iend) return;
  if (isFirstSegment) {
    while (iend > istart) {
      resultKPI[iend - 1].StartTime =
        resultKPI[iend].StartTime - fraction < 0 ? resultKPI[iend].StartTime + 0.1 : resultKPI[iend].StartTime - fraction;
      iend--;
    }
  } else {
    while (istart < iend) {
      resultKPI[istart].StartTime = resultKPI[istart - 1].StartTime + fraction;
      istart++;
    }
  }
};

export const transformForSentenceTableData = (sentences, duration, participants) => {
  let sentencesData = [];
  if (sentences && sentences.length > 0) {
    sentences.forEach((item) => {
      sentencesData.push({
        start: item.StartTime.second,
        end: item.EndTime.second,
        speaker: item.ParticipantRole,
        speakerName: getParticipant(participants, item.ParticipantID, item.ParticipantRole),
        kpi: item.KPI?.map((obj) => obj.value), //?.map(({obj}) => obj.value)
        mkpi: item.MEDDIC_KPI?.map((obj) => obj.value),
        MessageID: item.MessageID,
        sentence: item.Content,
        gryphCards: item.GryphCards,
      });
    });
    sentencesData[sentencesData.length - 1].end = duration; // Use entire duration for sentence data
  }
  return sentencesData;
};

export const getSpeakTimeMetrics = (rowData, duration, isLongCall) => {
  let speakTimeMetricData = {};
  let seconds = 0,
    agentSeconds = 0,
    customerSeconds = 0,
    silenceSeconds = 0,
    totalSpeakerSeconds = 0;
  rowData.forEach((item) => {
    if (item?.MessageID !== "blankID") {
      if (item.speaker === HUMAN_AGENT || item.speaker === "HOST") {
        seconds = item.end - item.start;
        agentSeconds += seconds;
        totalSpeakerSeconds += seconds;
      } else if (item.speaker === END_USER) {
        seconds = item.end - item.start;
        customerSeconds += seconds;
        totalSpeakerSeconds += seconds;
      } else {
        seconds = item.end - item.start;
        totalSpeakerSeconds += seconds;
      }
    }
  });
  silenceSeconds = duration - totalSpeakerSeconds;
  speakTimeMetricData = {
    agent: {
      duration: toHHMMSS(parseInt(agentSeconds), isLongCall) + "s",
      percent:
        ((agentSeconds / duration) * 100).toFixed(0) < 10
          ? "0" + ((agentSeconds / duration) * 100).toFixed(0) + "%"
          : ((agentSeconds / duration) * 100).toFixed(0) + "%",
    },
    customer: {
      duration: toHHMMSS(parseInt(customerSeconds), isLongCall) + "s",
      percent:
        ((customerSeconds / duration) * 100).toFixed(0) < 10
          ? "0" + ((customerSeconds / duration) * 100).toFixed(0) + "%"
          : ((customerSeconds / duration) * 100).toFixed(0) + "%",
    },
    silence: {
      duration: toHHMMSS(parseInt(silenceSeconds), isLongCall) + "s",
      percent:
        ((silenceSeconds / duration) * 100).toFixed(0) < 10
          ? "0" + ((silenceSeconds / duration) * 100).toFixed(0) + "%"
          : ((silenceSeconds / duration) * 100).toFixed(0) + "%",
    },
    totalCallDuration: duration,
    totalSpeakerDuration: parseInt(totalSpeakerSeconds),
    isLongCall: isLongCall,
  };
  return speakTimeMetricData;
};

export const returnColorForChart = (score) => {
  if (score > T_NEGATIVE && score < T_POSITIVE) {
    return COLOR_TJ_YELLOW;
  } else if (score >= T_VERY_POSITIVE) {
    return COLOR_TJ_GREEN;
  } else if (score >= T_POSITIVE && score < T_VERY_POSITIVE) {
    return COLOR_TJ_LIGHT_GREEN;
  } else if (score <= T_VERY_NEGATIVE) {
    return COLOR_TJ_RED;
  } else if (score <= T_NEGATIVE && score > T_VERY_NEGATIVE) {
    return COLOR_TJ_ORANGE;
  }
};

export const sentimentScoreDivColor = (score, initialArray) => {
  let itemToReturn = initialArray;
  if (score > T_NEGATIVE && score < T_POSITIVE) {
    itemToReturn = ["#FFDE68", "#947300", "#C09602"]; // background, text, border colors
  } else if (score >= T_VERY_POSITIVE) {
    itemToReturn = ["#82BC58", "#3B5B25", "#6BA442"];
  } else if (score >= T_POSITIVE && score < T_VERY_POSITIVE) {
    itemToReturn = ["#D6DF73", "#616818", "#AFBB2B"];
  } else if (score <= T_VERY_NEGATIVE) {
    itemToReturn = ["#E55149", "#5B0703", "#B2211A"];
  } else if (score <= T_NEGATIVE && score > T_VERY_NEGATIVE) {
    itemToReturn = ["#F3844B", "#782E08", "#BF490D"];
  }
  return itemToReturn;
};

const getTransformForBulletsForVideo = (chartData, typeKPI, option) => {
  let data = [{}];
  if (chartData.length > 0) {
    if (typeKPI === "kpis") {
      let records = chartData.filter((i) => i.ParticipantRole.includes(AGENT) && i.KPI.some((e) => e.Value === option));
      if (records.length !== 0) {
        records.forEach((item) => {
          data.push(insertBullet(item, item.KPI, option));
        });
      }
    } else if (typeKPI === "mkpis") {
      let records = chartData.filter((i) => i.ParticipantRole.includes(AGENT) && i.MEDDIC_KPI.some((e) => e.Value === option));
      if (records.length !== 0) {
        records.forEach((item) => {
          data.push(insertBullet(item, item.MEDDIC_KPI, option));
        });
      }
    } else if (typeKPI === "ckpis") {
      let records = chartData.filter((i) => !i.ParticipantRole.includes(AGENT) && i.KPI.some((e) => e.Value === option));
      if (records.length !== 0) {
        records.forEach((item) => {
          data.push(insertBullet(item, item.KPI, option));
        });
      }
    }
  }
  return data;
};

export const transformForBullets = (chartData, typeKPI, option, participants, mediaType) => {
  let isVideo = mediaType === "video" ? true : false;
  let data = [{}];
  if (!isVideo) {
    if (typeKPI === "kpis") {
      let records = chartData.filter((i) => i.ParticipantRole.includes(AGENT) && i.KPI.some((e) => e.Value === option));
      if (records.length !== 0) {
        records.forEach((item) => {
          data.push(insertBullet(item, item.KPI, option));
        });
      }
    } else if (typeKPI === "mkpis") {
      let records = chartData.filter((i) => i.ParticipantRole.includes(AGENT) && i.MEDDIC_KPI.some((e) => e.Value === option));
      if (records.length !== 0) {
        records.forEach((item) => {
          data.push(insertBullet(item, item.MEDDIC_KPI, option));
        });
      }
    } else if (typeKPI === "ckpis") {
      let records = chartData.filter((i) => i.ParticipantRole.includes(CUSTOMER) && i.KPI.some((e) => e.Value === option));
      if (records.length !== 0) {
        records.forEach((item) => {
          data.push(insertBullet(item, item.KPI, option));
        });
      }
    }
  } else {
    data = getTransformForBulletsForVideo(chartData, typeKPI, option, participants, mediaType);
  }
  data = data.filter((obj) => {
    return Object.keys(obj).length !== 0 ? true : false;
  });
  return data;
};

export const insertBullet = (item, typeKPI, arrKPI, option, kpiInfos) => {
  let objectToReturn = {};
  let index = arrKPI.findIndex((e) => e.Value === option);
  objectToReturn.StartTime = arrKPI[index].StartTime;
  objectToReturn.ParticipantRole = DUMMY;
  objectToReturn.ParticipantId = DUMMY;
  objectToReturn.kpi = option;
  objectToReturn.disabled = false;
  // objectToReturn.color = getColorbyKPIConfidence(item, typeKPI);
  objectToReturn.color = getKPIColorbyPolarity(arrKPI, option, kpiInfos);
  if (typeKPI === "kpis") {
    objectToReturn.width = 5;
    objectToReturn.height = 9;
    objectToReturn.rotation = 0;
    objectToReturn.cornerRadiusBottomLeft = 2;
    objectToReturn.cornerRadiusBottomRight = 2;
    objectToReturn.cornerRadiusTopLeft = 2;
    objectToReturn.cornerRadiusTopRight = 2;
  } else if (typeKPI === "mkpis") {
    objectToReturn.width = 7;
    objectToReturn.height = 7;
    objectToReturn.rotation = 45;
    objectToReturn.cornerRadiusBottomLeft = 0;
    objectToReturn.cornerRadiusBottomRight = 0;
    objectToReturn.cornerRadiusTopLeft = 0;
    objectToReturn.cornerRadiusTopRight = 0;
  } else if (typeKPI === "ckpis") {
    objectToReturn.width = 7;
    objectToReturn.height = 7;
    objectToReturn.rotation = 0;
    objectToReturn.cornerRadiusBottomLeft = 0;
    objectToReturn.cornerRadiusBottomRight = 0;
    objectToReturn.cornerRadiusTopLeft = 0;
    objectToReturn.cornerRadiusTopRight = 0;
  }
  return objectToReturn;
};

export const transformNewKeys = (chartData, typeKPI, kpisToShow, participants, mediaType, kpiInfos) => {
  let isVideo = mediaType === "video" ? true : false;
  let data = [];
  if (!isVideo) {
    kpisToShow?.forEach((option) => {
      if (typeKPI === "kpis") {
        let records = chartData[0].filter((i) => i.ParticipantRole.includes(AGENT) && i.KPI.some((e) => e.Value === option));
        if (records.length !== 0) {
          records.forEach((item) => {
            data.push(insertBullet(item, typeKPI, item.KPI, option, kpiInfos));
          });
        }
      } else if (typeKPI === "mkpis") {
        let records = chartData[0].filter((i) => i.ParticipantRole.includes(AGENT) && i.MEDDIC_KPI.some((e) => e.Value === option));
        if (records.length !== 0) {
          records.forEach((item) => {
            data.push(insertBullet(item, typeKPI, item.MEDDIC_KPI, option, kpiInfos));
          });
        }
      } else if (typeKPI === "ckpis") {
        let records = chartData[0].filter((i) => i.ParticipantRole.includes(CUSTOMER) && i.KPI.some((e) => e.Value === option));
        if (records.length !== 0) {
          records.forEach((item) => {
            data.push(insertBullet(item, typeKPI, item.KPI, option, kpiInfos));
          });
        }
      }
    });
    data = data.filter((obj) => {
      return Object.keys(obj).length !== 0 ? true : false;
    });
  } else {
    data = getTransformNewKeysForVideo(chartData, typeKPI, kpisToShow, participants, mediaType, kpiInfos);
  }
  return data;
};

const getTransformNewKeysForVideo = (chartData, typeKPI, kpisToShow, participants, mediaType, kpiInfos) => {
  let data = [];
  let hostParticipantID;
  participants?.forEach((p) => {
    if (p.custom_role === "HUMAN_AGENT") {
      hostParticipantID = p.id;
    }
  });
  for (let idx in chartData) {
    // eslint-disable-next-line no-loop-func
    kpisToShow?.forEach((option) => {
      if (chartData[idx].length > 0) {
        if (typeKPI === "kpis") {
          let records = chartData[idx].filter((i) => i.ParticipantId.includes(hostParticipantID) && i.KPI.some((e) => e.Value === option));
          if (records.length !== 0) {
            records.forEach((item) => {
              if (!data[idx]) {
                data[idx] = [];
              }
              data[idx].push(insertBullet(item, typeKPI, item.KPI, option, kpiInfos));
            });
          }
        } else if (typeKPI === "mkpis") {
          let records = chartData[idx].filter((i) => i.ParticipantId.includes(hostParticipantID) && i.MEDDIC_KPI.some((e) => e.Value === option));
          if (records.length !== 0) {
            records.forEach((item) => {
              if (!data[idx]) {
                data[idx] = [];
              }
              data[idx].push(insertBullet(item, typeKPI, item.MEDDIC_KPI, option, kpiInfos));
            });
          }
        } else if (typeKPI === "ckpis") {
          let records = chartData[idx].filter((i) => !i.ParticipantId.includes(hostParticipantID) && i.KPI.some((e) => e.Value === option));
          if (records.length !== 0) {
            records.forEach((item) => {
              if (!data[idx]) {
                data[idx] = [];
              }
              data[idx].push(insertBullet(item, typeKPI, item.KPI, option, kpiInfos));
            });
          }
        }
      }
    });
  }
  data = data.filter((obj) => {
    return Object.keys(obj).length !== 0 ? true : false;
  });
  return data;
};

const addDummyKPI = (kpiList, tempData) => {
  if (tempData) {
    kpiList.unshift({
      kpi: "None",
      StartTime: tempData[0]?.StartTime,
      ParticipantRole: "KPI",
      disabled: true,
      ParticipantId: "KPI",
    });
    kpiList.push({
      kpi: "None",
      StartTime: tempData[tempData.length - 1]?.EndTime,
      ParticipantRole: "KPI",
      disabled: true,
      ParticipantId: "KPI",
    });
  }
};

export const getKPIData = (chartData, kpisToShow, participants, mediaType, kpiInfos, recordings, isMeddic, isVideo) => {
  let kpiList = [];
  let kpiData = transformNewKeys(chartData, "kpis", kpisToShow[0], participants, mediaType, kpiInfos);
  let ckpiData = transformNewKeys(chartData, "ckpis", kpisToShow[1], participants, mediaType, kpiInfos);
  let mkpiData = isMeddic ? transformNewKeys(chartData, "mkpis", kpisToShow[2], participants, mediaType, kpiInfos) : [];
  if (isVideo) {
    for (let i = 0; i < recordings; i++) {
      kpiList.push([]);
    }
    if (kpiData.length || ckpiData.length || mkpiData.length) {
      if (recordings >= 1) {
        for (let i = 0; i < recordings; i++) {
          kpiList[i] = [...(kpiData[i] || []), ...(ckpiData[i] || []), ...(mkpiData[i] || [])];
        }
      } else {
        kpiList = [...kpiData, ...ckpiData, ...mkpiData];
      }
    }
    chartData.forEach((recordingData, index) => {
      let tempData = recordingData.filter((i) => i.MediaIndex === index).sort((a, b) => a.StartTime - b.StartTime);
      if (kpiList.length) {
        addDummyKPI(kpiList[index], tempData);
      }
    });
  } else {
    kpiList = [[...kpiData, ...ckpiData, ...mkpiData]];
    let tempData = chartData[0]?.sort((a, b) => a.StartTime - b.StartTime);
    addDummyKPI(kpiList[0], tempData);
  }
  return kpiList;
};

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 0.3) + min);
};

export const randomIntFromInterval2 = (item) => {
  // min and max included
  return Math.floor(Math.random() * (item.EndTime - item.StartTime + 1) + item.StartTime);
};

export const getActualFileDuration = (file) => {
  let arr = file.sentences;
  let lastSentenceEndsAt = arr.length ? arr.slice(-1)[0].EndTime.second : 0;
  let defaultDuration = file.Duration ? file.Duration : lastSentenceEndsAt;
  return lastSentenceEndsAt > defaultDuration ? lastSentenceEndsAt : defaultDuration;
};

export const updatedSpeaker = (speaker = "") => {
  // Fix Speakers
  if (speaker === null || speaker === "") return UNKNOWN_SPEAKER;
  if (speaker === HUMAN_AGENT) {
    speaker = AGENT;
  } else if (speaker === END_USER) {
    speaker = CUSTOMER;
  } else if (speaker === "HOST") {
    speaker = "AGENT";
  }
  return speaker;
};

export const getAgentUpdatedSpeaker = (speaker = "", firstName, lastName) => {
  // Fix Speakers
  if (speaker === null || speaker === "") return UNKNOWN_SPEAKER;
  if (speaker === HUMAN_AGENT || speaker === AGENT) {
    speaker = firstName + " " + lastName;
  } else if (speaker === END_USER || speaker === CUSTOMER) {
    speaker = CUSTOMER;
  } else if (speaker === "HOST") {
    speaker = "HOST";
  }
  return speaker;
};

export const getParticipant = (participants, id, role) => {
  let name = updatedSpeaker(role);
  if (participants === undefined || participants.length === 0 || id === "") return name;
  participants.forEach((participant) => {
    if (participant.id === id || id.includes(participant.id)) {
      name = participant.name;
    }
  });
  return name;
};

export const getParticipantId = (participants, id) => {
  let pid = id;
  if (participants.length === 0) return id;
  participants.forEach((participant) => {
    if (participant.id === id || id.includes(participant.id)) {
      pid = participant.id;
    }
  });
  return pid;
};

export const getSentimentSVG = (score) => {
  if (score > T_NEGATIVE && score < T_POSITIVE) {
    return <span></span>;
  } else if (score >= T_VERY_POSITIVE) {
    return <DoubleUPSVG style={{ marginTop: "50%", width: "15px", height: "15px" }}></DoubleUPSVG>;
  } else if (score >= T_POSITIVE && score < T_VERY_POSITIVE) {
    return <ArrowUPSVG style={{ marginTop: "50%", width: "15px", height: "15px" }}></ArrowUPSVG>;
  } else if (score <= T_VERY_NEGATIVE) {
    return <DoubleDownSVG style={{ marginTop: "50%", width: "15px", height: "15px" }}></DoubleDownSVG>;
  } else if (score <= T_NEGATIVE && score > T_VERY_NEGATIVE) {
    return <ArrowDownSVG style={{ marginTop: "50%", width: "15px", height: "15px" }}></ArrowDownSVG>;
  }
};

export const getSentimentString = (score) => {
  if (score > T_NEGATIVE && score < T_POSITIVE) {
    return "Neutral";
  } else if (score >= T_VERY_POSITIVE) {
    return "Very Positive";
  } else if (score >= T_POSITIVE && score < T_VERY_POSITIVE) {
    return "Positive";
  } else if (score <= T_VERY_NEGATIVE) {
    return "Very Negative";
  } else if (score <= T_NEGATIVE && score > T_VERY_NEGATIVE) {
    return "Negative";
  }
};

export const getStartTimes = (sentences) => {
  let startTimeArr = [];
  startTimeArr = sentences.map((sentence) => {
    return sentence.StartTime.second;
  });
  return startTimeArr;
};

export const getCurrentSentenceIndex = (sentencesStartTimeArray, currentTime) => {
  let currentSentenceIndex = 0;
  let i = 0;
  for (i in sentencesStartTimeArray) {
    if (parseFloat(currentTime.toFixed(2)) < parseFloat(sentencesStartTimeArray[i].toFixed(2))) {
      currentSentenceIndex = i - 1;
      break;
    }
    i++;
  }

  if (sentencesStartTimeArray && i === sentencesStartTimeArray.length) {
    currentSentenceIndex = sentencesStartTimeArray.length - 1;
  }
  return currentSentenceIndex;
};

export const getNextSentenceStartTime = (sentencesStartTimeArray, currentTime) => {
  let nextSentenceStartTime = 0;
  for (let i in sentencesStartTimeArray) {
    if (currentTime < sentencesStartTimeArray[i]) {
      nextSentenceStartTime = sentencesStartTimeArray[i];
      break;
    }
  }
  return nextSentenceStartTime;
};

export const getCellColor = (kpi, kpiInfos) => {
  try {
    let kpiInfo = kpiInfos?.info?.find((kpiList) => kpiList.kpi === kpi);
    return kpiInfo.color;
  } catch (e) {}
};

export const getStylesMaterial = (name, selectedCriticalBehaviours, theme) => {
  return selectedCriticalBehaviours.indexOf(name) === -1
    ? {
        fontWeight: theme.typography.fontWeightRegular,
      }
    : {
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#928f8fa8",
        color: "white",
      };
};

export const getTaggedOptionsObject = (sentences) => {
  let salesKPIs = [];
  let meddicKPIs = [];
  let customerKPIs = [];

  sentences?.forEach((sentence) => {
    let salesKPI = [];
    let meddicKPI = [];
    let customerKPI = [];

    if (sentence.ParticipantRole === "HUMAN_AGENT" || sentence.ParticipantRole === "HOST") {
      salesKPI = sentence.KPI?.map((kpi) => {
        return kpi.value;
      });
      meddicKPI = sentence.MEDDIC_KPI?.map((mkpi) => {
        return mkpi.value;
      });

      salesKPIs = addKpiIfNotPresent(salesKPI, salesKPIs);
      meddicKPIs = addKpiIfNotPresent(meddicKPI, meddicKPIs);
    } else {
      customerKPI = sentence.KPI?.map((kpi) => {
        return kpi.value;
      });
      customerKPIs = addKpiIfNotPresent(customerKPI, customerKPIs);
    }
  });

  let returnObj = {};
  returnObj.salesKPIs = salesKPIs;
  returnObj.meddicKPIs = meddicKPIs;
  returnObj.customerKPIs = customerKPIs;

  return returnObj;
};

export const getRawSentences = (sentencesToSend, filteredSentences, kpiInfos) => {
  return sentencesToSend.map((sentence, key) => {
    let newSentence = sentence;

    if (key <= filteredSentences.length && cleanupTranscription(newSentence.Content) === filteredSentences[key].sentence) {
      try {
        newSentence.MEDDIC_KPI = filteredSentences[key].mkpi.map((k) => {
          let kpiObject = {};
          kpiObject.KpiID = getIdByMeddicKpi(k, kpiInfos); // if meddic then get Meddic KPI ID
          kpiObject.value = k;
          return kpiObject;
        });
      } catch (err) {}
      try {
        newSentence.KPI = filteredSentences[key].kpi.map((k) => {
          let kpiObject = {};
          kpiObject.KpiID = getIdByKpi(k, kpiInfos);
          kpiObject.value = k;
          return kpiObject;
        });
      } catch (err) {}
    }
    return newSentence;
  });
};

export const cleanupTranscription = (sentence = "") => {
  // Fix Spelling for Gryphon/Gryphon Networks
  if (sentence == null) return null;
  sentence = sentence.replaceAll(
    /(?:gryphons?)|(?:griffon)|(?:griffins?)|(?:griffiths?)|(?:grifone)|(?:girffen)|(?:griffore)|(?:gryffindor)|(?:gryffin)|(?:grypa)|(?:gry)/gi,
    "Gryphon"
  );
  sentence = sentence.replaceAll(/(?:gryphon\snetworks?)/gi, "Gryphon Networks");
  sentence = sentence.replaceAll(/(?:griffs?)|(?:grif)|(?:grift)|(?:mcgriff)/gi, "Gryph");
  return sentence;
};

export const getIdByKpi = (kpi, kpiInfos) => {
  try {
    let kpiInfo = kpiInfos?.info?.find(
      (kpiList) => (kpiList.kpi === kpi || kpiList.kpi_old === kpi) && (kpiList.kpiModel === "Kpi" || kpiList.kpiModel === "DfKpi")
    );
    return kpiInfo.id;
  } catch (e) {
    return "kp0";
  }
};

// added by Akshay
export const getIdByMeddicKpi = (kpi, kpiInfos) => {
  try {
    let kpiInfo = kpiInfos?.info?.find((kpiList) => (kpiList.kpi === kpi || kpiList.kpi_old === kpi) && kpiList.kpiModel === "MeddicKpi");
    return kpiInfo.id;
  } catch (e) {
    return "mkp0";
  }
};

export const addKpiIfNotPresent = (kpis, arr) => {
  let externalArray = arr;
  if (kpis === undefined) {
    kpis = [];
  }
  try {
    if (kpis[0] !== "None") {
      kpis?.forEach((k) => {
        if (externalArray.indexOf(k) < 0) {
          externalArray.push(k);
        }
      });
    }
  } catch (e) {}
  return externalArray;
};

// concatenate all elements of array. if arr.length > 1, have a ',' between elements.
// separate normal and meddic KPIs by '|'.

export const getStringFromArray = (arr, meddic, meddicArr) => {
  let arrayToReturn = " ";
  let normalArray = " ";
  let meddicArray = " ";
  if (meddic) {
    meddicArr?.forEach((mkpi, key) => {
      key > 0 ? (meddicArray += `, ${mkpi}`) : (meddicArray += `${mkpi}`);
    });
  }
  arr?.forEach((kpi, key) => {
    key > 0 ? (normalArray += `, ${kpi}`) : (normalArray += `${kpi}`);
  });
  if (meddic) {
    if (arr?.length > 0 && meddicArr?.length > 0) {
      arrayToReturn = `${normalArray} | ${meddicArray}`;
    } else if (arr?.length > 0 && meddicArr?.length <= 0) {
      arrayToReturn = `${normalArray}`;
    } else if (arr?.length <= 0 && meddicArr?.length > 0) {
      arrayToReturn = `${meddicArray}`;
    } else {
      arrayToReturn = "";
    }
  } else {
    arrayToReturn = arr?.length > 0 ? `${normalArray}` : "";
  }
  return arrayToReturn;
};

export const getColorByKpi = (kpiInfo, kpi) => {
  try {
    let kpiInfou = kpiInfo.info.find((kpiList) => kpiList.kpi === kpi);
    return kpiInfou.color;
  } catch (e) {
    return "kp0";
  }
};

export const getUniqueColorForEachKpi = (kpiInfo, kpi) => {
  try {
    let kpiInfou = kpiInfo.info.find((kpiList) => kpiList.kpi === kpi);
    return kpiInfou.uniqueColor;
  } catch (e) {
    return "black";
  }
};

export const getColorbyKPIConfidence = (item, typeKPI) => {
  if (typeKPI === "kpis") {
    var KPIVal = item.KPI[0].Value;
    var KPIConfVal = item.KPI_Confidence[[KPIVal]];
    return KPIConfVal < 0.33
      ? new am4core.color("#C92A2A")
      : KPIConfVal >= 0.33 && KPIConfVal < 0.66
      ? new am4core.color("#1971C2")
      : KPIConfVal >= 0.66
      ? new am4core.color("#0D8244")
      : new am4core.color("#1971C2");
  } else {
    return new am4core.color("#1971C2");
  }
};

export const getKPIColorbyPolarity = (arrKPI, option, kpiInfos) => {
  let filteredKPI = arrKPI?.filter((k) => k.Value?.toLowerCase() === option.toLowerCase());
  let filteredKPIInfo = kpiInfos?.info?.filter((info) => info?.id?.toLowerCase() === filteredKPI[0]?.KpiID?.toLowerCase());
  if (filteredKPIInfo[0]?.polarity?.toLowerCase() === POLARITY_POSITIVE.toLowerCase()) {
    // POSITIVE
    return COLOR_TJ_GREEN;
  } else if (filteredKPIInfo[0]?.polarity?.toLowerCase() === POLARITY_NEGATIVE.toLowerCase()) {
    // NEGATIVE
    return COLOR_TJ_RED;
  } else {
    // NEUTRAL
    return COLOR_TJ_YELLOW;
  }
};

export const getPolarityById = (KpiID, kpiInfos) => {
  let polarity = POLARITY_NEUTRAL;
  let filteredKPIInfo = kpiInfos?.info?.filter((info) => info?.id?.toLowerCase() === KpiID?.toLowerCase());
  if (filteredKPIInfo[0]?.polarity) {
    // POLARITY
    polarity = filteredKPIInfo[0]?.polarity?.toLowerCase();
  }
  return polarity;
};

export const formatTimeStamp = (timeStamp) => {
  const timeStampDate = timeStamp;
  const dateInMillis = timeStampDate._seconds * 1000;
  let formattedTimeStamp = new Date(dateInMillis).toDateString() + " at " + new Date(dateInMillis).toLocaleTimeString();
  return formattedTimeStamp;
};

export const formatTimeStampToEST = (timeStamp) => {
  const dateInMillis = timeStamp._seconds * 1000;
  let formattedTimeStamp =
    DateTime.fromMillis(dateInMillis).setLocale("en-US").setZone("America/New_York").toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) +
    " at " +
    DateTime.fromMillis(dateInMillis).setLocale("en-US").setZone("America/New_York").toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
  return formattedTimeStamp;
};

export const formatISOTimeToET = (timeStamp) => {
  let formattedTimeStamp =
    DateTime.fromISO(timeStamp).setLocale("en-US").setZone("America/New_York").toLocaleString({ month: "2-digit", day: "2-digit" }) +
    " at " +
    DateTime.fromISO(timeStamp).setLocale("en-US").setZone("America/New_York").toLocaleString(DateTime.TIME_SIMPLE) +
    " " +
    DateTime.fromISO(timeStamp).setLocale("en-US").setZone("America/New_York").toFormat("ZZZZ");
  return formattedTimeStamp;
};

export const getSplitConvIDArr = (convId) => {
  const fields = convId.split("_");
  let fieldsArr = [];
  fieldsArr.push(fields[0].toString());
  if (fields.length >= 2) {
    fieldsArr.push(Number(fields[1].toString()));
  }
  // return Number(fields[1].toString());
  return fieldsArr;
};

export const ObjectToQueryParams = (params) => {
  let queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  return queryString;
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "0";
};

export const getFileType = (URL) => {
  if (URL.toString().includes(".mp3")) {
    return "mp3";
  } else if (URL.toString().includes(".wav")) {
    return "wav";
  } else if (URL.toString().includes(".mp4")) {
    return "mp4";
  } else if (URL.toString().includes(".txt")) {
    return "txt";
  } else if (URL.toString().includes(".png")) {
    return "png";
  } else {
    return "wav";
  }
};

export const getAcroyn = (str) => {
  if (str && str !== "") {
    let acronym = str.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
    return acronym.substring(0, 2).toUpperCase();
  } else return;
};

export const getDiffBetweenArrayItems = (array1, array2) => {
  return array1.concat(array2).filter((item) => !(array1.includes(item) && array2.includes(item)));
};

export const countOccurrences = (arr, val) => {
  return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
};

export const isEmpty = (value) => {
  if (value) {
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    //TODO FOR OBJECT
    return false;
  }
  return true;
};

export const isEmptySections = (sectionList) => {
  //TODO: Need to change map or foreach
  for (let index = 0; index < sectionList.length; index++) {
    const sectionData = sectionList[index];
    const { sectionTitle, bulletList = [] } = sectionData;
    if (isEmpty(sectionTitle)) {
      return true;
    }
    for (let i = 0; i < bulletList.length; i++) {
      const { bulletType, bulletHeader, bulletContent, bulletLink, bulletlinkurl } = bulletList[i];
      if (isEmpty(bulletHeader)) return true;
      if (bulletType === "text" && isEmpty(bulletContent)) return true;
      if (bulletType === "link" && (isEmpty(bulletLink) || isEmpty(bulletlinkurl))) return true;
    }
  }
  return false;
};

export const noTranscriptObjectByMediaIndex = (index) => {
  return {
    MediaIndex: index,
    MessageID: "PCA_CUSTOM",
    LanguageCode: "en-US",
    Content: "Transcript is not available for this call.",
    StartTime: { nanosecond: 0, second: 0 },
    EndTime: { nanosecond: 0, second: 0 },
    ParticipantID: "",
    ParticipantRole: "HOST",
    CustomRole: "HUMAN_AGENT",
    KPI: [{ value: "None", KpiID: "kp0" }],
    MEDDIC_KPI: [{ value: "None", KpiID: "mkp0" }],
    SentimentAnalysis: { score: 0, magnitude: 0 },
  };
};

export const signedURLToFilePath = (url, isAudio) => {
  // USED FOR VIDEO SNIPPETS - Media, Transcript URL
  let path = "";
  if (url) {
    let pathStep1 = queryString.parseUrl(url)?.url;
    let pathStep2 = decodeURI(pathStep1);
    let pathStep3 = pathStep2.replace("https://storage.googleapis.com/", "gs://");
    if (isAudio) {
      path = pathStep3.replace(".mp4", ".m4a");
    } else {
      path = pathStep3;
    }
  }
  return path;
};

export const getScriptAdherenceLabelThresholdIcon = (script_adherence_threshold, value) => {
  if (value !== null) {
    if (value >= script_adherence_threshold.high) return <PositiveIcon />;
    else if (value < script_adherence_threshold.high && value >= script_adherence_threshold.low) return <NegativeIcon />;
    else if (value < script_adherence_threshold.low) return <VeryNegativeIcon />;
  } else {
    return <VeryNegativeIcon />;
  }
};

export const getScriptAdherenceThresholdIcon = (script_adherence_threshold, adherenceScoresMap) => {
  if (adherenceScoresMap !== null && adherenceScoresMap.length > 0) {
    let scriptAdheredCount = adherenceScoresMap.filter((v) => v >= script_adherence_threshold.high).length;
    if (scriptAdheredCount === adherenceScoresMap.length) return <PositiveIcon />;
    else if (scriptAdheredCount >= adherenceScoresMap.length / 2) return <NegativeIcon />;
    else return <VeryNegativeIcon />;
  } else {
    return <VeryNegativeIcon />;
  }
};

export const getG1ThresholdColor = (value) => {
  if (value !== null) {
    if (value >= G1_SCORE_THRESHOLD.high) return "#0D8244";
    else if (value < G1_SCORE_THRESHOLD.high && value >= G1_SCORE_THRESHOLD.mid) return "#4BC957";
    else if (value < G1_SCORE_THRESHOLD.mid && value >= G1_SCORE_THRESHOLD.low) return "#FD9A00";
    else if (value < G1_SCORE_THRESHOLD.low) return "#C92A2A";
  } else {
    return "#C92A2A";
  }
};

// export const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: "#495057",
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#495057",
//     fontSize: "13px",
//   },
// }));

export const renderComplianceScore = (complianceScore) => {
  if (complianceScore?.Rules) {
    let scoreResult = calculateScoreResult(complianceScore?.Rules);
    switch (scoreResult.result) {
      case "Pass":
        return (
          <BootstrapTooltip title={scoreResult.score} placement="top" arrow>
            <GNCompliancePass></GNCompliancePass>
          </BootstrapTooltip>
        );
      case "Fail":
        return (
          <BootstrapTooltip title={scoreResult.score} placement="top" arrow>
            <GNComplianceFail></GNComplianceFail>
          </BootstrapTooltip>
        );
      case "Disable":
        return (
          <BootstrapTooltip title="Compliance: Inactive" placement="top" arrow>
            <GNComplianceDisabled></GNComplianceDisabled>
          </BootstrapTooltip>
        );
      default:
        return (
          <BootstrapTooltip title="Compliance: Inactive" placement="top" arrow>
            <GNComplianceDisabled></GNComplianceDisabled>
          </BootstrapTooltip>
        );
    }
  } else {
    return (
      <BootstrapTooltip title="Compliance: Inactive" placement="top" arrow>
        <GNComplianceDisabled></GNComplianceDisabled>
      </BootstrapTooltip>
    );
  }
};

export const calculateScoreResult = (rules) => {
  const rulesWithoutNA = rules.filter((rules) => rules.Status !== "NA");
  const totalRulesApplied = rulesWithoutNA?.length;
  const totalRulesSuccess = rules.filter((rules) => rules.Status === "Success")?.length;
  if (totalRulesApplied === 0) {
    return { result: "Disable", score: "" };
  } else if (totalRulesSuccess === totalRulesApplied) {
    return { result: "Pass", score: `Compliance: ${totalRulesSuccess}/${totalRulesApplied}` };
  } else {
    return { result: "Fail", score: `Compliance: ${totalRulesSuccess}/${totalRulesApplied}` };
  }
};

export const getComplianceStatusIcon = (value) => {
  if (value !== null) {
    if (value === "Success") return <NeutralSuccessIcon width="15" height="15" />;
    else if (value === "NA") return undefined;
    else if (value === "Failure" || value === "Response Timeout") return <FailureIcon />;
  } else {
    return undefined;
  }
};

export const getOverallComplianceStatusIcon = (rules) => {
  if (rules !== null && rules.length > 0) {
    let res = calculateScoreResult(rules);
    if (res.result === "Pass") return <NeutralSuccessIcon width="20" height="20" />;
    else return <FailureIcon />;
  } else {
    return <FailureIcon />;
  }
};

export const renderComplianceStatusIcon = (status, msg, redirectMsgId, props) => {
  if (status) {
    switch (status) {
      case "Success":
        return (
          <BootstrapTooltip title={msg} placement="top" arrow>
            <StyledIconButton onClick={() => (redirectMsgId ? props.seekByFastForward(redirectMsgId) : undefined)}>
              <GNCompliancePass></GNCompliancePass>
            </StyledIconButton>
          </BootstrapTooltip>
        );
      case "Failure":
        return (
          <BootstrapTooltip title={msg} placement="top" arrow>
            <StyledIconButton onClick={() => (redirectMsgId ? props.seekByFastForward(redirectMsgId) : undefined)}>
              <GNComplianceFail> </GNComplianceFail>
            </StyledIconButton>
          </BootstrapTooltip>
        );
      case "Triggered":
        return (
          <BootstrapTooltip title={msg} placement="top" arrow>
            <StyledIconButton onClick={() => (redirectMsgId ? props.seekByFastForward(redirectMsgId) : undefined)}>
              <GNComplianceTrigger></GNComplianceTrigger>
            </StyledIconButton>
          </BootstrapTooltip>
        );
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
