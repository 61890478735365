import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#495057",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#495057",
    fontSize: "13px",
  },
}));

export default BootstrapTooltip;
