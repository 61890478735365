/*
    TO USE THIS COMPONENT:
        1. When you call /getUserSession, store the value returned in "sessionTimeout" (at the top level) in your redux or other store
        2. Modify the sessionTimeout constant below to read that state variable (line 18).
        3. Modify the gryphWSURL (line 16) to read the value for the gryph API url (https://client.gryphonnetworks.com or https://clienttest.gryphonnetworks.com) from wherever you have it stored.
            * Depending on how you have it stored, this may require a change to the construction of the sessionURL constants (lines 33 and 61) 
        3. Import and add this component as a child of whatever component has your logout routine, or somewhere where it can trigger the routine.
            a. Add an "onLogout" prop on the component that points to your logout routine
            b. If your logout routine is triggered by something else, modify the "logout" const (line 22) or the two references to logout (lines 81 and 89) to trigger it
*/
import * as React from "react";
import * as cookieUtils from "../../assets/cookie-utils";
import config from "../../config/config";

const ActivityMonitor = (props) => {
  const [sessionTimeout, setSessionTimeout] = React.useState(props?.userInfo?.sessionTimeout);
  const [appActive, setAppActive] = React.useState(new Date());
  const [appActiveCheck, setAppActiveCheck] = React.useState(new Date());
  const [lastSessionExtend, setLastSessionExtend] = React.useState(new Date());
  const [userInfo, setUserInfo] = React.useState(props?.userInfo);
  // added focus state to handle switching back to app after logging out from another window
  const [hasFocus, setHasFocus] = React.useState(true);

  const logout = () => {
    cookieUtils.redirectUserToMainLogin();
  };

  const minute = 60 * 1000;

  React.useEffect(() => {
    (async () => {
      try {
        setUserInfo(props.userInfo);
        setSessionTimeout(props.userInfo.sessionTimeout);
      } catch (err) {}
    })();
  }, [props.userInfo.userKey]);

  React.useEffect(() => {
    const activeDiff = Math.floor((appActiveCheck.getTime() - appActive.getTime()) / minute);
    const extendDiff = Math.floor((new Date().getTime() - lastSessionExtend.getTime()) / minute);
    // modified this for tighter checks to handle logout from another app
    const extendCheck = sessionTimeout > 10 ? 2 : 1;

    const extendSession = () => {
      const sessionURL = config.API_EXTEND_SESSION;
      fetch(sessionURL, {
        credentials: "include",
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            // throw an error and handle it if needed
          }
        })
        .then(function (jsonResponse) {
          if (jsonResponse.retCode !== 0) {
            // throw an error and handle it
          } else {
            setLastSessionExtend(new Date());
          }
        })
        .catch(function () {
          // evaluate and handle the error
          // don't really *need* anything, as this indicates other problems are likely
          // the app will most likely either time out on its own or will extend correctly the next time it is called
          logout();
        });
    };

    const checkSessionExpiration = () => {
      const extend_sessionURL = config.API_EXTEND_SESSION;
      const sessionURL = extend_sessionURL.replace("extendUserSession", "checkUserSession");
      fetch(sessionURL, {
        credentials: "include",
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            // throw an error and handle it
          }
        })
        .then(function (jsonResponse) {
          if (jsonResponse.retCode > 1) {
            // throw an error and handle it
          } else if (jsonResponse.retCode === 1) {
            // session is expired
            //alert('Session timeout reached, logging out.');
            logout();
          }
        })
        .catch(function () {
          // evaluate and handle the error
          logout();
        });
    };

    //console.log('current inactive minutes: ', activeDiff);
    //console.log('current minutes since last extend: ', extendDiff);
    //console.log('idleTimeout: ', idleTimeout);
    //console.log('activeDiff: ', activeDiff);
    //console.log('activeDiff < idleTimeout: ', activeDiff < idleTimeout);
    // UNCOMMENT THIS SECTION WHEN READY TO GO LIVE WITH LOGOUT
    // THIS SECTION DOES THE FULL CHECK AND LOGS THE USER OUT IF THE SESSION IS EXPIRED

    if (activeDiff < userInfo.sessionTimeout) {
      // if the session has not been extended in 5 minutes, but has been active within that same period, extend the session.
      if (extendDiff >= extendCheck && activeDiff < extendCheck) {
        extendSession();
      }
    }

    // modified to make session expiration check happen more frequently to handle logout from another app.
    if (activeDiff >= extendCheck) {
      checkSessionExpiration();
    }
    // COMMENT OUT THE REST OF THIS WHEN LOGOUT GOES LIVE
    // THIS SECTION JUST EXTENDS THE SESSION AUTOMATICALLY, REGARDLESS OF IDLE TIME
    // if (extendDiff >= extendCheck) {
    //     //console.log('extending session: ', new Date());
    //     extendSession();
    // }
  }, [minute, userInfo.sessionTimeout, appActive, appActiveCheck, lastSessionExtend]);

  React.useEffect(() => {
    // function to update time of last activity
    const flagAppAsActive = () => {
      setAppActive(new Date());
    };
    // add event listeners to keep app flagged as active
    const events = [
      "click",
      "wheel",
      "keydown",
      "scroll",
      "mousedown",
      "mouseup",
      //"focus",
      // ... add other events here if needed ...
    ];
    const addActivityListeners = () => {
      events.forEach((event) => {
        window.addEventListener(event, flagAppAsActive, { passive: true });
      });
      // add new event listeners for focus and blur to handle switching back to app after logging out from another window
      window.addEventListener(
        "focus",
        () => {
          setHasFocus(true);
        },
        { passive: true }
      );
      window.addEventListener(
        "blur",
        () => {
          setHasFocus(false);
        },
        { passive: true }
      );
      return () => {
        events.forEach((event) => window.removeEventListener(event, flagAppAsActive, { passive: true }));
        // corresponding removeEventListener for focus and blur
        window.removeEventListener(
          "focus",
          () => {
            setHasFocus(true);
          },
          { passive: true }
        );
        window.removeEventListener(
          "blur",
          () => {
            setHasFocus(false);
          },
          { passive: true }
        );
      };
    };
    const monitorSession = () => {
      setAppActiveCheck(new Date());
      // cut monitoring interval to handle app without focus running in efficiency mode
      const checkInterval = document.visibilityState === "visible" ? minute : minute / 2;
      setTimeout(monitorSession, checkInterval);
    };

    addActivityListeners();
    monitorSession();
  }, []);
  // monitor focus state to handle switching back to app after logging out from another window
  // only need to make a check if the app is receivng the focus
  React.useEffect(() => {
    //console.log('hasFocus: ', hasFocus);
    if (hasFocus) {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() - (sessionTimeout + 1));
      setAppActive(dt);
      setTimeout(() => {
        setAppActive(new Date());
      }, 100);
    }
  }, [hasFocus]);

  return <div style={{ display: "none" }}>Nothing to see here.</div>;
};

export default ActivityMonitor;
