import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import callActionDockStyles from "./CallActionsDock.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CallFlagReview from "../call-flag-review/CallFlagReview";
import CallNotes from "../call-notes/CallNotes";
import CallSnippet from "../call-snippet/CallSnippet";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import IconButton from "@material-ui/core/IconButton";
import CallMetadata from "../call-metadata/CallMetadata";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import HtmlTooltip from "../../assets/components/HtmlTooltip";
import Fade from "@material-ui/core/Fade";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Chip from "@mui/material/Chip";
import { saveAs } from "file-saver";
import * as constants from "../../assets/constants";
import CallRating from "../call-rating/CallRating";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Snackbar from "@material-ui/core/Snackbar";
import Fab from "@material-ui/core/Fab";
import TextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { getExternalAccessTokenAPI } from "../../services/auth";
import Alert from "@material-ui/lab/Alert";
import config from "../../config/config";

const styles = callActionDockStyles;

const CallActionsDock = (props) => {
  let initialDockState = {
    open: true,
    anchorEl: null,
  };

  const initialErrorObject = {
    isError: false,
    msgError: "",
  };

  const externalTokenRequestObject = {
    id: props.fileId,
    userKey: props.userKeyInSession,
    expireMinutes: 20160, //default duration set to 14 days
  };

  const { classes } = props;
  const [dockState, setDockState] = React.useState(initialDockState);
  const [downloadOption, setDownloadOption] = React.useState(false);
  const [shareOption, setShareOption] = React.useState(false);
  const [errorObject, setErrorObject] = React.useState(initialErrorObject);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [externalAccessURL, setExternalAccessURL] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleCloseErrorSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorObject({
      isError: false,
      msgError: "",
    });
  };

  const handleCopyToClipboard = () => {
    // works only on secure HTTPS context
    try {
      navigator.clipboard.writeText(externalAccessURL);
      setOpenSnack(true);
    } catch (err) {
      setErrorObject({
        isError: true,
        msgError: "ERR: Unable to copy URL to clipboard",
      });
    }
  };

  const generateExternalToken = async () => {
    try {
      // Call to API
      setOpen(!open);
      let res = await getExternalAccessTokenAPI(externalTokenRequestObject);
      if (res?.retCode === "0" && res?.retMsg === "success") {
        formatAndSetExternalAccessURL(res.token);
        setOpen(false);
        setOpenModal(true);
      } else {
        setErrorObject({
          isError: true,
          msgError: "ERR: Failed to create external access URL",
        });
        setOpen(false);
      }
    } catch (e) {
      setErrorObject({
        isError: true,
        msgError: "ERR: Failed to create external access URL",
      });
      setOpen(false);
    }
  };

  const formatAndSetExternalAccessURL = (token) => {
    let host = window.location.href.split("?")[0];
    let resURL = host + `?externalToken=${token}`;
    setExternalAccessURL(resURL);
  };

  const handleDrawerOpen = () => {
    setDockState({ ...dockState, open: !dockState.open });
    setDownloadOption(false);
    setShareOption(false);
    props.toggleOffSnip();
  };

  const handleDrawerSnippetOpen = () => {
    setDockState({ ...dockState, open: !dockState.open });
    setShareOption(true);
  };

  const handleOpenExternalAccessLink = () => {
    window.open(externalAccessURL, "_blank");
  };

  const handleOpenSourceCallLink = () => {
    let sourceURL = config.PCA_CLIENT_URL + `${props.fileId}`;
    window.open(sourceURL, "_blank");
  };

  const handleOpen = () => {
    setShareOption(false);
    setDownloadOption(!downloadOption);
  };

  const handleShareOptionOpen = () => {
    setDownloadOption(false);
    setShareOption(!shareOption);
  };

  const handleSaveFile = (isMediaFile) => {
    const link = isMediaFile ? props.downloadAudioURL : props.downloadTranscriptUrl;

    const downloadFileName = props.id + "." + constants.getFileType(link);
    fetch(link)
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, downloadFileName);
      })
      .catch((e) => {
        setErrorObject({
          isError: true,
          msgError: `ERR: Unable to download file - ${e}`,
        });
      });

    setDownloadOption(false);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames({
            [classes.drawerBase]: true,
            [classes.drawerOpen]: dockState.open,
            [classes.drawerClose]: !dockState.open,
          }),
        }}
        open={dockState.open}
      >
        <div
          className={classNames({
            [classes.actionContentOpen]: props.isSnippetOpen,
            [classes.actionContentClose]: !props.isSnippetOpen,
            [classes.actionContentDocked]: !props.isSnippetOpen && !dockState.open,
          })}
        >
          <div
            className={classNames(classes.drawerToggleDiv, {
              [classes.drawerToggleDivOpen]: dockState.open,
              [classes.drawerToggleDivClose]: !dockState.open,
            })}
          >
            <IconButton aria-label="delete" onClick={handleDrawerOpen} className={classes.drawerIcon}>
              <ChevronRightIcon
                fontSize="large"
                className={classes.drawerArrowIcon}
                classes={{
                  root: dockState.open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </div>
          <CallMetadata {...props} dockState={dockState.open} handleDrawerOpen={handleDrawerOpen} setErrorObject={setErrorObject}></CallMetadata>
          <React.Fragment>
            <Divider className={classes.divider} />
            <CallRating
              {...props}
              isAdmin={props.isAdmin}
              convID={props.fileId}
              userKey={props.userKeyInSession}
              dockState={dockState.open}
              handleDrawerOpen={handleDrawerOpen}
              setErrorObject={setErrorObject}
            />
          </React.Fragment>
          <Divider className={classes.divider} />
          {!props.externalAccess ? (
            <React.Fragment>
              <CallNotes {...props} dockState={dockState.open} handleDrawerOpen={handleDrawerOpen} setErrorObject={setErrorObject}></CallNotes>
              <Divider className={classes.divider} />
              <CallFlagReview
                {...props}
                dockState={dockState.open}
                handleDrawerOpen={handleDrawerOpen}
                setErrorObject={setErrorObject}
              ></CallFlagReview>
            </React.Fragment>
          ) : undefined}
          <Divider className={classes.divider} />
        </div>
        {dockState.open && props.isSnippetOpen ? (
          <div className={classes.actionSnippet}>
            {props.isSnippetCall || !props.noTranscript ? <CallSnippet {...props}></CallSnippet> : undefined}
          </div>
        ) : undefined}
        <div
          className={classNames(classes.actionFooter, {
            [classes.actionFooterOpen]: dockState.open,
            [classes.actionFooterClose]: !dockState.open,
            [classes.actionFooterAudio]: !props.isVideoFile,
            [classes.actionFooterVideo]: props.isVideoFile,
            [classes.actionFooterCloseVideo]: !dockState.open && props.isVideoFile,
          })}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={errorObject.isError}
            autoHideDuration={2000}
            onClose={handleCloseErrorSnack}
            message={errorObject.msgError}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseErrorSnack}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          >
            <Alert severity="error">{errorObject.msgError}</Alert>
          </Snackbar>
          {!props.externalAccess ? (
            <React.Fragment>
              {dockState.open ? (
                <Stack className={classNames(classes.actionStack)} direction="column" spacing={1}>
                  {props.isSnippetCall ? (
                    <HtmlTooltip
                      className={classes.snippetActionButton}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            <b>Access entire call analysis</b>
                          </Typography>
                          <em>{"Click here to review entire audio call"}</em>
                        </React.Fragment>
                      }
                      placement="top"
                      arrow
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 200 }}
                    >
                      <Button
                        className={classes.actionButton}
                        variant="contained"
                        startIcon={<OpenInNewOutlinedIcon />}
                        onClick={handleOpenSourceCallLink}
                      >
                        <Typography className={classes.buttonText}>Source Call</Typography>
                      </Button>
                    </HtmlTooltip>
                  ) : (
                    <React.Fragment>
                      {props.isSnippetOpen ? (
                        <Button
                          className={classes.actionButton}
                          variant="contained"
                          startIcon={<HighlightOffOutlinedIcon />}
                          onClick={props.onToggleSnip}
                        >
                          <Typography className={classes.buttonText}>Cancel</Typography>
                        </Button>
                      ) : (
                        <React.Fragment>
                          {shareOption ? (
                            <div className={classes.downloadButtonDiv}>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">
                                      <b>Share Externally</b>
                                    </Typography>
                                    <em>{"Share this call outside of your organization"}</em>
                                  </React.Fragment>
                                }
                                placement="top"
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 200 }}
                              >
                                <Chip
                                  className={classes.inlineButton}
                                  label="External"
                                  onClick={() => {
                                    generateExternalToken();
                                  }}
                                  color="primary"
                                  variant="outlined"
                                />
                              </HtmlTooltip>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">
                                      <b>Share Snippet</b>
                                    </Typography>
                                    <em>{"Click here OR Click and drag through a part of the graph to review and share a snippet"}</em>
                                  </React.Fragment>
                                }
                                placement="top"
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 200 }}
                              >
                                <Chip
                                  className={classes.inlineButton}
                                  label="Snippet"
                                  onClick={props.onToggleSnip}
                                  disabled={props.noTranscript || !props.downloadAudioURL}
                                  color="primary"
                                  variant="outlined"
                                />
                              </HtmlTooltip>
                            </div>
                          ) : undefined}
                          <Button
                            className={classes.actionButton}
                            variant="contained"
                            startIcon={<FileUploadOutlinedIcon />}
                            onClick={handleShareOptionOpen}
                          >
                            <Typography className={classes.buttonText}>Share</Typography>
                          </Button>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  {props.isVideoFile ? (
                    <React.Fragment>
                      {props.isVideoFile && downloadOption ? (
                        <div className={classes.downloadButtonDiv}>
                          <HtmlTooltip
                            className={classes.snippetActionButton}
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  <b>Download</b>
                                </Typography>
                                <em>{"Click here to download media"}</em>
                              </React.Fragment>
                            }
                            placement="top"
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 200 }}
                          >
                            <Chip
                              disabled={!props.downloadAudioURL}
                              className={classes.inlineButton}
                              label="Media"
                              onClick={() => {
                                handleSaveFile(true);
                              }}
                              color="primary"
                              variant="outlined"
                            />
                          </HtmlTooltip>
                          <HtmlTooltip
                            className={classes.snippetActionButton}
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  <b>Download</b>
                                </Typography>
                                <em>{"Click here to download transcript"}</em>
                              </React.Fragment>
                            }
                            placement="top"
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 200 }}
                          >
                            <Chip
                              disabled={props.noTranscript || !props.downloadTranscriptUrl}
                              className={classes.inlineButton}
                              label="Transcript"
                              onClick={() => {
                                handleSaveFile(false);
                              }}
                              color="primary"
                              variant="outlined"
                            />
                          </HtmlTooltip>
                        </div>
                      ) : undefined}
                      <Button className={classes.actionButton} variant="contained" startIcon={<CloudDownloadIcon />} onClick={handleOpen}>
                        <Typography className={classes.buttonText}>Download</Typography>
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HtmlTooltip
                        className={classes.snippetActionButton}
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              <b>Download</b>
                            </Typography>
                            <em>{"Click to download entire audio call"}</em>
                          </React.Fragment>
                        }
                        placement="top"
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                      >
                        <Button
                          disabled={!props.downloadAudioURL}
                          className={classes.actionButton}
                          variant="contained"
                          startIcon={<CloudDownloadIcon />}
                          onClick={handleSaveFile}
                        >
                          <Typography className={classes.buttonText}>Download</Typography>
                        </Button>
                      </HtmlTooltip>
                    </React.Fragment>
                  )}

                  <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={openSnack}
                    autoHideDuration={2000}
                    onClose={handleCloseSnack}
                    message="Copied to clipboard"
                    action={
                      <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                  <Modal
                    className={classes.modal}
                    open={openModal}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModal}>
                      <div className={classes.paper}>
                        <div className={classes.backDropDiv}>
                          <TextField
                            inputProps={{ type: "url" }}
                            defaultValue={externalAccessURL}
                            helperText="Access your external call here"
                            label="URL"
                            variant="outlined"
                            className={classes.externalTextField}
                          />
                          <div className={classes.externalButtonDiv}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <em>{"Open Link"}</em>
                                </React.Fragment>
                              }
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              placement="bottom"
                            >
                              <Fab size="small">
                                <OpenInNewIcon className={classes.snippetActionButton} onClick={handleOpenExternalAccessLink} />
                              </Fab>
                            </HtmlTooltip>
                            <HtmlTooltip
                              className={classes.snippetActionButton}
                              title={
                                <React.Fragment>
                                  <em>{"Copy to clipboard"}</em>
                                </React.Fragment>
                              }
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              placement="bottom"
                            >
                              <Fab size="small">
                                <FileCopyIcon onClick={handleCopyToClipboard} />
                              </Fab>
                            </HtmlTooltip>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                </Stack>
              ) : (
                <React.Fragment>
                  <Stack spacing={1}>
                    <div>
                      <Divider className={classes.divider} />
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography className={styles.participantText}>Share</Typography>
                          </React.Fragment>
                        }
                        placement="right"
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                      >
                        <Button
                          variant="text"
                          sx={{
                            marginTop: "8px",
                            borderRadius: "10px",
                            outline: "0 !important",
                            paddingLeft: "4px",
                            height: "48px",
                          }}
                          onClick={handleDrawerSnippetOpen}
                        >
                          <FileUploadOutlinedIcon color="primary" />
                        </Button>
                      </HtmlTooltip>
                    </div>
                    <Divider className={classes.divider} />
                    <HtmlTooltip
                      className={styles.snippetActionButton}
                      title={
                        <React.Fragment>
                          <Typography className={styles.participantText}>Download</Typography>
                        </React.Fragment>
                      }
                      placement="right"
                      arrow
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 200 }}
                    >
                      <Button
                        variant="text"
                        sx={{
                          borderRadius: "10px",
                          outline: "0 !important",
                          paddingLeft: "4px",
                          height: "48px",
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <CloudDownloadIcon />
                      </Button>
                    </HtmlTooltip>
                  </Stack>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : undefined}
        </div>
      </Drawer>
    </div>
  );
};

CallActionsDock.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CallActionsDock);
