import "./App.css";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Navbar from "./nav-bar/Navbar";
import ErrorBoundary from "./helper/Error/Error";
import FileView from "./post-call-analysis/listOfCalls";
import NewInsights from "./post-call-analysis/new-insights/NewInsights";
import ModelHistory from "./admin-view/modelHistory";
import Choice from "./admin-view/Choice";
import CssBaseline from "@mui/material/CssBaseline";
import CustomError from "./helper/CustomError/CustomError";
import "@fontsource/inter"; // Defaults to weight 400.
import Search from "./elastic-search/Search";
import ActivityMonitor from "./post-call-analysis/timeout-warning-modal/ActivityMonitor";
import UtteranceFlags from "./admin-view/UtteranceFlags";
import UserAddedKPIs from "./admin-view/UserAddedKPIs";
import UserAddedKpisConfigurationTool from "./admin-view/UAK-Configuration.js";
import * as cookieUtils from "./assets/cookie-utils.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1971C2",
    },
    secondary: {
      main: "#D96932",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});
const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    height: "calc(100% - 64px)",
    overflow: "auto",
  },
  inner: {
    padding: "0px",
    height: "100%",
  },
  app: {
    width: "100%",
    height: "100%",
  },
}));

const App = () => {
  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0,
    reportLevel: "",
    reportEntities: "",
    sessionTimeout: null,
  };
  const classes = useStyles();
  const [userInfo, setUserInfo] = React.useState(initialUserInfo);

  useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
      } catch (err) {}
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div className={classes.app}>
          <Navbar userInfo={userInfo} />
          <ErrorBoundary>
            <div className={classes.root}>
              <Switch>
                <Route exact path="/files" component={() => <FileView userInfo={userInfo} />} />
                <Route exact path="/choice" component={() => <Choice userInfo={userInfo} />} />
                <Route exact path="/Model-History" component={() => <ModelHistory userInfo={userInfo} />} />
                <Route exact path="/search" component={() => <Search userInfo={userInfo} />} />
                <Route exact path="/UtteranceFlags" component={() => <UtteranceFlags userInfo={userInfo} />} />
                <Route exact path="/UserAddedKPIs" component={() => <UserAddedKPIs userInfo={userInfo} />} />
                <Route exact path="/UAK-Configurations" component={() => <UserAddedKpisConfigurationTool userInfo={userInfo} />} />
                <Route path="/:fileId" component={(props) => <NewInsights {...props} userInfo={userInfo} />} />
                <Route exact path="*" component={<CustomError />} />
                <Route path="/error-page" element={<CustomError />} />
                <Route path="/redirect" element={<Redirect to="/error-page" />} />
              </Switch>
              <ActivityMonitor userInfo={userInfo} />
            </div>
          </ErrorBoundary>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
